<div mat-dialog-title>
  <div class="title-icon">
    <i class="fa fa-exclamation-triangle"></i>
  </div>
  <div class="title-text">Something went wrong</div>
</div>
<mat-dialog-content>
  An unknown error has occurred.<br />
  Please contact <a href="support@speak-see.com">support@speak-see.com</a> so
  that we can help you solve this.
  <pre>{{ data.error?.message | json }}</pre>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
