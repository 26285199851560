import { Component, OnDestroy, OnInit } from '@angular/core';

import { PUCK_COLORS_HEX, PUCK_COLORS_NAME_PAIR } from '../../constants/color';

import {
  IRemoteSettings,
  SettingsService,
} from '../../services/settings.service';

import { Observable, Subject } from 'rxjs';

import { Microphone } from '../../shared/models/microphone.model';
import { take } from 'rxjs/operators';
import { UpdateMicrophoneSettingsRequest } from '@speaksee/speaksee-angular-core';

@Component({
  selector: 'app-display-settings',
  templateUrl: './display-settings.component.html',
  styleUrls: ['./display-settings.component.scss'],
})
export class DisplaySettingsComponent implements OnInit, OnDestroy {
  public showMessageMetadata$: Observable<boolean>;
  public kit: Observable<IRemoteSettings>;
  public microphones: Observable<Microphone[]>;
  public colorList: string[];
  public colorNames = PUCK_COLORS_NAME_PAIR;
  private micUpdates: UpdateMicrophoneSettingsRequest = {
    microphones: [],
  };
  private unsubscribe = new Subject();

  constructor(private settingsService: SettingsService) {
    this.showMessageMetadata$ = this.settingsService.showMessageMetadata$;
    this.kit = this.settingsService.getRemoteSetSettings();
    this.colorList = PUCK_COLORS_HEX;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  toggleMetadata() {
    this.settingsService.toggleDisplayMetadata();
  }

  public async micSettingsChanged(micId, color: string, name: string) {
    // Get array all microphones
    let microphones = [];
    // Update all mics when all is selected
    microphones = (await this.kit.pipe(take(1)).toPromise()).microphones.map(
      (x) => x.mic_id
    );

    let micSwap = null;
    // Check if any mic already has the desired color
    for (const mic of microphones) {
      if (mic.color === color) {
        micSwap = mic.mic_id;
      }
    }

    // Update only the current mic with new color if micSwap is empty
    /* eslint-disable */
    if (micSwap === null) {
      this.micUpdates.microphones.push({
        mic_id: micId,
        color_hex: color,
        name,
      } as any);
    } else {
      this.micUpdates.microphones.push({
        mic_id: micSwap,
        color_hex: microphones.find((el) => el.mic_id === micSwap).color,
      } as any);
      this.micUpdates.microphones.push({
        mic_id: micId,
        color_hex: color,
        name,
      } as any);
      /* eslint-enable */
    }

    try {
      await this.settingsService
        .updateMicSettings(this.micUpdates)
        .pipe(take(1))
        .toPromise();
      this.micUpdates.microphones = [];
    } catch (error) {
      console.log(error);
      this.micUpdates.microphones = [];
    }
  }
}
