import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: 'https://e7924aa6d4e360ee9d11029ebe514a8f@o449251.ingest.us.sentry.io/4506932195229696',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      block: ['.mask-me', '[data-hj-suppress]'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, //  Capture 20% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0, // don't capture replays without error
  replaysOnErrorSampleRate: 1.0, // capture all errors
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
