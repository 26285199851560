<div (click)="snackBarRef.dismissWithAction()" class="custom-snack">
  <div mat-icon-button aria-label="Button to Scroll Down" class="icon-button">
    <mat-icon class="icon">keyboard_double_arrow_down</mat-icon>
  </div>
  <!-- NOTE: Discuss issues with dynamic size -->
  <h1 appSpeechBubbleText class="scroll-down-text"></h1>
  <div *ngIf="(data | async) > 0" class="notification">
    {{((data | async)>9)?'9+':(data | async) }}
  </div>
</div>
