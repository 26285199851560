import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, map, tap } from 'rxjs/internal/operators';

@Injectable()
export class TranslateService {
  private domParser = new DOMParser();
  private cache = [];

  constructor(private http: HttpClient) {}

  async translate(text, languageIn, languageOut) {
    const target = languageOut ? languageOut.split('-')[0] : 'nl';
    const source = languageIn ? languageIn.split('-')[0] : 'nl';

    const fromCache = this.cache.find(
      (item) =>
        item.input === text && item.source === source && item.target === target
    );

    if (target === source) {
      return text;
    } else if (fromCache) {
      return fromCache.output;
    } else {
      // console.log(source);
      return await this.http
        .get(
          // eslint-disable-next-line max-len
          `https://www.googleapis.com/language/translate/v2?key=AIzaSyD41p4IsDfG-IicWDwVtsgZsHGROfEhVvo&source=${source}&target=${target}&q=${encodeURIComponent(
            text
          )}`
        )
        .pipe(
          map((result: any) => result.data.translations[0].translatedText),
          map(
            (translatedText) =>
              this.domParser.parseFromString(translatedText, 'text/html')
                .documentElement.textContent
          ),
          tap((output) => {
            this.cache.push({
              input: text,
              source,
              target,
              output,
            });
          }),
          first()
        )
        .toPromise();
    }
  }
}

export interface GoogleObj {
  q: string;
  source: string;
  target: string;
  format: string;
}
