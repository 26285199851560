import { AccountService } from '@app/services/account.service';
import { Injectable } from '@angular/core';

import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnonGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.accountService.userValue?.token) {
      return true;
    }
    this.router.navigate(['/microphone-kits']);
    return false;
  }
}
