/* eslint-disable @typescript-eslint/naming-convention */
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';

// import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { orderBy } from 'lodash';

import { EncryptionService } from '@app/modules/encryption/encryption.service';
import { License } from '@app/modules/license/license.types';
import { Message } from '@app/shared/models/app.model';
import { Microphone } from '../shared/models/microphone.model';
import { ParsedMessage } from '@app/shared/models/parsed-message.model';
import { PUCK_COLORS_NAME_PAIR } from '@app/constants/color';
import { SettingsService } from './settings.service';
// import { transcript } from '@app/mock/2-person.mock';

@Injectable({
  providedIn: 'root',
})
/**
 * @name SpeechService class
 * @description This class deals with retrieving information from the firestore.
 * This information is then pushed on into a message stream for messages and a mic stream
 * for the discovered microphones out of the messages
 */
export class SpeechService {
  private _activeMics: BehaviorSubject<Microphone[]> = new BehaviorSubject([]);
  private _messages: BehaviorSubject<Message[]> = new BehaviorSubject([]);
  private dbData: Observable<Message[]>;
  private staticTextBubbles: Message[] = [];

  private readonly MAX_ACTIVE_MESSAGES = 35; // how many active changes we keep track off
  private readonly CLEAN_UP_MESSAGE_COUNT = 5; // how many changes we make inactive when threshold is reached

  constructor(
    private db: AngularFirestore,
    private settingsService: SettingsService,
    private encryptionService: EncryptionService
  ) {}

  get activeMics$(): Observable<Microphone[]> {
    return this._activeMics.asObservable();
  }

  get messages$(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  public async updateMic(id: number, mic: Partial<Microphone>) {
    this.activeMics$
      .pipe(first())
      .subscribe((microphones) => {
        const index = microphones.findIndex((x) => x.id === id);
        if (index !== -1) {
          microphones[index].active = mic?.active ?? microphones[index].active;
          microphones[index].color = mic?.color ?? microphones[index].color;
          microphones[index].name = mic?.name ?? microphones[index].name;
        }

        this._activeMics.next([...microphones]);
      })
      .unsubscribe();
  }

  /**
   * This function returns an observable that triggers on new incoming data on firestore in the selected collection
   * It orders data by a ascending timestamp and pushes them into the messages stream.
   *
   * It also checks for a new mic ID and pushes a new mic in stream.
   * If the mic already exists it activates the mic
   */
  public startListener(licenses: License[]): Observable<Message[]> {
    this._messages.next([]);
    this._activeMics.next([]);

    const listeners: Observable<Message[]>[] = [];
    for (const license of licenses) {
      listeners.push(this.createDatabaseListenerForLicense(license));
    }

    this.dbData = combineLatest(listeners).pipe(
      map((messagesProviders) =>
        messagesProviders.reduce((a, b) => [...a, ...b], [])
      )
    );

    // ------------------------------------------------------------------------
    // NOTE: uncomment if you want fake data
    // TODO: Move out of this class and make it configurable.
    // ------------------------------------------------------------------------
    // const subject = new Subject<Message[]>();
    // const asyncBoy = async () => {
    //   for (const t of transcript) {
    //     subject.next(t);
    //     await new Promise<void>((resolve) => {
    //       setTimeout(() => {
    //         resolve();
    //         // NOTE: This is how we change time
    //       }, 250);
    //     });
    //   }
    // };
    // asyncBoy();
    // this.dbData = subject.asObservable();
    // ------------------------------------------------------------------------

    this.staticTextBubbles = [];
    const changeHistory: ParsedMessage[] = [];
    // (window as any).transcriptStorage = [];
    localStorage.removeItem('transcriptDebug');
    return this.dbData.pipe(
      //enable for storing transcripts for replaying
      // tap((data) => {
      //   let oldData: any[];
      //   try {
      //     oldData = JSON.parse(localStorage.getItem('transcriptDebug')) || [];
      //   } catch {
      //     oldData = [];
      //   }
      //   oldData.push(data);
      //   (window as any).transcriptStorage.push(data);
      //   localStorage.setItem('transcriptDebug', JSON.stringify(oldData));
      // }),
      tap((messages)=>{
        for(const message of messages) {
          const mics = this._activeMics.value;
          const existingMic = mics.find((x) => x.id === message.micId);

          if (!existingMic) {
            try {
              const micSettings = this.settingsService.getMicSettings(
                message.micId
              );
              this._activeMics.next([
                ...mics,
                new Microphone(
                  micSettings.name ||
                    'MIC ' + ((micSettings as any)?.index ?? 0),
                  micSettings.color_hex,
                  micSettings.mic_id,
                  'MIC ' + ((micSettings as any)?.index ?? 0)
                ),
              ]);
            } catch (e) {
              this._activeMics.next([
                ...mics,
                new Microphone(
                  PUCK_COLORS_NAME_PAIR[message.micColor.hex],
                  message.micColor.hex,
                  message.micId,
                  'MIC ' + message.micId
                ),
              ]);
            }
          }
      }
      }),
      // ---------------------------------------------------------------------
      // FIX: set `is_encrypted` flag to false by default if it's not set
      // TODO: remove this once all messages includes the `is_encrypted` flag
      map((changes: Message[]) =>
        changes.map((messageUpdate) => ({
          ...messageUpdate,
          is_encrypted: messageUpdate.is_encrypted ?? false,
        }))
      ),
      // ---------------------------------------------------------------------

      map((changes: Message[]) => {
        this.updateChangeHistory(changes, changeHistory);

        // Flatmap the combined messages into a single message array
        let singleMessages: Message[] = changeHistory.reduce<Message[]>(
          (result, history) => [...result, ...history.getMessages()],
          []
        );

        // Order everything on update
        singleMessages = orderBy(singleMessages, 'updated_at', 'asc');

        // Combine messages that are consecutively from the same speaker and
        // not final
        const activeTextBubbles = this.generateTextBubbles(
          changeHistory,
          singleMessages
        );

        // cleanup crew
        if (activeTextBubbles.length >= this.MAX_ACTIVE_MESSAGES) {
          const readyToBeStaticBubbles = this.cleanInactiveHistory(
            activeTextBubbles,
            changeHistory,
            this.CLEAN_UP_MESSAGE_COUNT
          );
          this.staticTextBubbles.push(...readyToBeStaticBubbles);
        }

        return [...this.staticTextBubbles, ...activeTextBubbles];
      }),
      tap((messages: Message[]) => {
        this._messages.next(messages);
        return messages;
      })
    );
  }

  private getLastMessageFromSameMic(
    id,
    messages: Message[]
  ): Message | undefined {
    for (let index = messages.length - 1; index >= 0; index--) {
      if (messages[index].documentID === id) {
        return messages[index];
      }
    }
    return undefined;
  }

  /**
   * Takes in a list of messages and loops over them
   * to see if the message should remain separate or combined with a previous message
   *
   * It will combine messages based on the following rules
   * 1. If there was a previous message found for the same mic that was said in the time span of 2 seconds of the new message
   * 2. If the last  message was from the same microphone
   *
   * @param messages all known messages the user received
   * @returns a new list of messages with some messages combined on behavior
   */
  private generateTextBubbles(
    history: ParsedMessage[],
    messages: Message[]
  ): Message[] {
    return messages.reduce((result: Message[], value: Message) => {
      // Don't allow empty things
      if (value.message.trim() === '') {
        return result;
      }

      // last message from same mic/speaker
      const lastSame = this.getLastMessageFromSameMic(value.documentID, result);

      // expand if previous same speaker was still talking (avoid premature
      // splitting)
      if (lastSame) {
        const currentTimestamp = new Date(value.updated_at).getTime();
        const prevTimestamp = new Date(lastSame.updated_at).getTime();
        const elapsed = currentTimestamp - prevTimestamp;
        if (
          !lastSame.is_final &&
          elapsed <=
            (this.settingsService.localSettings.segmentationSilenceTimeout ||
              2500)
        ) {
          lastSame.is_final = value.is_final;
          lastSame.updated_at = value.updated_at;
          lastSame.message += ' ' + value.message;
          return result;
        }
      }

      const lastMessage = result[result.length - 1];
      const lastSpeaker = lastMessage && lastMessage.micId === value.micId;
      if (
        lastSpeaker &&
        (!this.settingsService.localSettings.splitOnFinal ||
          !lastMessage.is_final)
      ) {
        lastMessage.is_final = value.is_final;
        lastMessage.updated_at = value.updated_at;
        lastMessage.message += ' ' + value.message;
      } else {
        result.push(value);
      }

      return result;
    }, []);
  }

  /**
   * Parses the changes received and updates the change history accordingly.
   *
   * @param changes - The array of new changes to be parsed.
   * @param changeHistory - The array of parsed message history.
   */
  private updateChangeHistory(
    changes: Message[],
    changeHistory: ParsedMessage[]
  ) {
    for (const messageUpdate of changes) {
      const history = this.staticTextBubbles.find(
        (change) => change.documentID === messageUpdate.documentID
      );
      if (history) {
        console.log(
          'change was found in history skipping it',
          messageUpdate.documentID
        );
        continue;
      }

      const cachedMessage = changeHistory.find(
        (change) => change.documentID === messageUpdate.documentID
      );

      // Cache a messageUpdate if it's not already cached
      if (!cachedMessage) {
        console.log(
          `Message ${messageUpdate.documentID} not cached, Cacheing it.`
        );
        changeHistory.push(
          new ParsedMessage(
            messageUpdate,
            this.settingsService.localSettings.onlyShowChangesOnFinal || false,
            this.encryptionService
          )
        );
        continue;
      }

      // Update the cachedMessage with the last messageUpdate data
      cachedMessage.addNewChange(messageUpdate);
    }
  }

  private cleanInactiveHistory(
    singleMessages: Message[],
    changeHistory: ParsedMessage[],
    cleanAmount: number
  ) {
    let readyForCleaning = 0;
    for (let i = 0; i < cleanAmount; i++) {
      if (singleMessages[i].is_final) {
        readyForCleaning++;
      }
    }

    if (readyForCleaning === cleanAmount) {
      const changes = changeHistory.splice(0, cleanAmount);
      console.log(
        'removing',
        readyForCleaning,
        changes.map((x) => x.documentID)
      );
      return singleMessages.splice(0, cleanAmount);
    }

    console.log('not ready for cleaning yet');
    return [];
  }

  private createDatabaseListenerForLicense(
    license: License
  ): Observable<Message[]> {
    switch (license.type) {
      case 'microphonekit':
        return this.db
          .collection(`devices/${license.firestoredb}/messages`, (ref) =>
            ref
              .orderBy('timestamp', 'desc')
              .where('timestamp', '>=', Date.now())
              .limit(25)
          )
          .valueChanges({ idField: 'documentID' }) as Observable<Message[]>;
      case 'autocaption':
        const listener = this.db
          .collection(`autocaption/${license.firestoredb}/messages`, (ref) =>
            ref
              .orderBy('timestamp', 'desc')
              .where('timestamp', '>=', Date.now())
              .limit(25)
          )
          .valueChanges({ idField: 'documentID' }) as Observable<Message[]>;

        // force AC data to have a certain color and name until we build settings
        return listener.pipe(
          map((messages) => {
            messages.forEach((message) => {
              message.device = 'autocaption';
              message.micColor = {
                hex: '#2B2421',
                name: 'autocaption',
              };
            });
            return messages;
          })
        );
    }
  }
}
