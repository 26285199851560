import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseError } from '@angular/fire/app/firebase';

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { AccountService } from '@app/services/account.service';
import { LanguageService } from '@app/services/language.service';
import { License } from '@app/modules/license/license.types';
import { Message } from '@app/shared/models/app.model';
import { Microphone } from '@app/shared/models/microphone.model';
import { NoSleepService } from '@app/services/no-sleep.service';
import { SpeechService } from '@app/services/speech.service';

import {
  IRemoteSettings,
  SettingsService,
} from '@app/services/settings.service';
import { SessionService } from '@app/services/session.service';
import { SpeakseeOrderService } from '@app/modules/license/license.service';

@HostListener('window:scroll', [])
@Component({
  selector: 'app-speech',
  templateUrl: './speech.component.html',
  styleUrls: ['./speech.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default,
})
export class SpeechComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollView') scrollView: ElementRef<HTMLDivElement>;

  public userActing = false;
  // TODO: Use messages Observable for getting new message data
  // this.messages.subscribe()
  // cancel subscription in ngDestroy
  // i can update a public variable for the notifications and send that to the snackbar
  public messages$: Observable<Message[]>;
  public microphones$: Observable<Microphone[]>;
  public messageLength: number;
  public kitLicenses: License[];
  public allLicenses: License[];

  private autoScrollEnabled = true;
  private scrollTimeout: any;
  private stopTranscription = new Subject();
  private session: License;

  constructor(
    route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private languageService: LanguageService,
    private speechService: SpeechService,
    private noSleepService: NoSleepService,
    private settingsService: SettingsService,
    private sessionService: SessionService,
    private orderService: SpeakseeOrderService
  ) {
    this.session = route.snapshot?.data.session;
    this.allLicenses = this.orderService.licenses;
    this.kitLicenses = this.allLicenses.filter(
      (license) => license.type === 'microphonekit'
    );
  }

  /**
   * Listen on all events that could perhaps trigger a user scroll
   * If so we inform our scrolling system to not trigger auto scroll until
   * interaction is over.
   */
  // NOTE: consider mouse grabbing scroll wheel or up/down arrow
  @HostListener('window:wheel', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  @HostListener('window:mouseup', ['$event'])
  @HostListener('window:touchstart', ['$event'])
  @HostListener('window:touchcancel', ['$event'])
  @HostListener('window:touchend', ['$event'])
  mouse($event) {
    switch ($event.type) {
      case 'wheel':
        this.userActing = !this.isAtBottom() || $event.deltaY < 0;
        break;
      case 'touchstart':
      case 'mousedown':
        this.userActing = true;
        break;
      case 'touchend':
      case 'touchstart':
      case 'mouseup':
        this.userActing = false;
        break;
    }
  }

  /**
   * Checks if the scroll view is at the bottom.
   *
   * @returns True if the scroll view is at the bottom, false otherwise.
   */
  isAtBottom() {
    const target = this.scrollView?.nativeElement;
    if (!target) {
      return true;
    }

    const current = target.offsetHeight + target.scrollTop;
    // const offset = 100;
    return current >= target.scrollHeight - 100;
  }

  /**
   * Handles the window scroll event and sets the autoScrollEnabled to true if
   * the scroll view is at the bottom.
   */
  onWindowScroll() {
    if (this.isAtBottom()) {
      clearTimeout(this.scrollTimeout);
      this.autoScrollEnabled = true;
    }

    if (this.userActing) {
      this.autoScrollEnabled = false;
    }
  }

  /**
   * Scrolls the view to the bottom.
   */
  scrollToBottom() {
    this.scrollTimeout = setTimeout(() => {
      this.scrollView.nativeElement.scrollTo({
        top: this.scrollView.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    });
  }

  ngOnInit(): void {
    // IDEA: Move this into a resolver?
    try {
      this.noSleepService.enable();
    } catch (e) {
      console.warn('Failed to enable wake lock', e);
    }

    this.microphones$ = this.speechService.activeMics$;

    this.settingsService.kit$
      .pipe(takeUntil(this.stopTranscription))
      .subscribe((settings: IRemoteSettings) => {
        for (const mic of settings.microphones) {
          this.speechService.updateMic(mic.mic_id, {
            color: mic.color_hex,
            name: mic.name,
          });
        }
      });
  }

  async ngAfterViewInit() {
    let languageSetFromInitLoad = false;
    const licensesToListenTo = [this.session];
    const acLicense = this.allLicenses.find((l) => l.type === 'autocaption');
    if (this.session.type === 'microphonekit' && acLicense) {
      licensesToListenTo.push(acLicense);
      await this.sessionService.startSession(acLicense).toPromise();
    }

    //this.settingsService.startMicSettingsPoller(this.stopTranscription);

    this.speechService
      .startListener(licensesToListenTo)
      .pipe(
        takeUntil(this.stopTranscription),

        // TODO: Move this into the speech.service
        // Catch firebase no permission error and redirect user to selector
        catchError((err: any) => {
          console.error(err);
          if ((err as FirebaseError).code === 'permission-denied') {
            this.accountService.logout();
          }
          this.stopTranscription.next();
          return of([]);
        }),
        map((messages: Message[]) => {
          if (!messages.length) {
            return messages;
          }

          if (!languageSetFromInitLoad) {
            const langCode = messages[messages.length - 1].language || 'en-US';
            this.languageService.setTranscriptionLanguage(langCode);
            this.languageService.setTranslationLanguage(langCode);
            this.settingsService.changeLocalLanguageOut(langCode);
            languageSetFromInitLoad = !languageSetFromInitLoad;
          }

          if (this.autoScrollEnabled) {
            clearTimeout(this.scrollTimeout);
            this.scrollToBottom();
          }
          return messages;
        }),
        shareReplay(1)
      )
      .subscribe(
        (messages: Message[]) => {
          this.messages$ = of(messages);
          this.messageLength = messages.length;
        },
        (err: any) => {
          console.error(err);
        }
      );
  }

  ngOnDestroy() {
    this.stopTranscription.next();
    try {
      this.noSleepService.disable();
    } catch (e) {
      console.warn('failed to disable wake lock', e);
    }
  }

  // https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
  trackByFn(index) {
    return index;
  }
}
