import { Component, Input, OnInit } from '@angular/core';
import { Microphone } from 'src/app/shared/models/microphone.model';

@Component({
  selector: 'app-microphone-bar',
  templateUrl: './microphone-bar.component.html',
  styleUrls: ['./microphone-bar.component.scss']
})
export class MicrophoneBarComponent implements OnInit {
  @Input() microphones: Microphone[] = [];
  values: any;
  constructor() {}

  ngOnInit() {}
}
