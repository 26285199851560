<div class="container">

  <div class="row">
    <div class="col">
      <p class="mt-4 text-center text-information">
        Adding words to the dictionary will allow SpeakSee to better understand unique names and places
      </p>
      <form>
          <div class="input-group">
              <input type="text" placeholder="Enter word" class="form-control" #dictionary>
              <span class="ml-3 input-group-btn d-block">
                <button class="btn btn-default btn-yellow" type="button" (click)="add(dictionary.value)">Add</button>
              </span>
          </div>
      </form>
      <div class="dictionary mt-4">
        <ul>
          <li *ngFor="let dict of sortedArray(dictionArray)">
            {{capitalize(dict.word)}}
            <i class="fa fa-trash float-right" (click)="remove(dict.word)"></i>
          </li>
        </ul>
        <a href="" routerLink="/speech">Back</a>
      </div>
    </div>
  </div>
</div>