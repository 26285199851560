import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IDBService {
  private name = 'speaksee';
  private version = 1;
  private db: IDBDatabase;

  constructor() {}

  async add(storeName: string, data: any): Promise<IDBValidKey> {
    const db = await this.getDatabase();
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);

    data.created_at = new Date();

    const request = store.add(data);
    return new Promise<IDBValidKey>((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  async get(storeName: string, key: any): Promise<any> {
    if (!key) {
      throw new Error('no key passed to indexdb get');
    }
    const db = await this.getDatabase();
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);

    const request = store.get(key);
    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  /**
   * Creates stores/tables in the specified database.
   *
   * @param database - The database in which to create the stores.
   * @returns
   */
  private createStores(database): void {
    // create stores|tables for encryption keys
    const objectStore = database.createObjectStore('keys', {
      keyPath: 'serial',
    });
    objectStore.createIndex('public_key', 'public_key', { unique: true });
    objectStore.createIndex('private_key', 'private_key', { unique: true });
    objectStore.createIndex('created_at', 'created_at', { unique: true });
  }

  private async getDatabase(): Promise<IDBDatabase> {
    if (this.db) {
      return this.db;
    }

    return new Promise((resolve, reject) => {
      let db: IDBDatabase;
      const dbOpenRequest = indexedDB.open(this.name, this.version);

      dbOpenRequest.onupgradeneeded = () =>
        this.createStores(dbOpenRequest.result);

      dbOpenRequest.onsuccess = () => {
        db = dbOpenRequest.result;
        resolve(db);
      };

      dbOpenRequest.onerror = () => reject(dbOpenRequest.error);
    });
  }
}
