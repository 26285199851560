/* eslint-disable */
export const PUCK_COLORS_HEX = [
  '#00AB84',
  '#DA291C',
  '#FF6900',
  '#69B3E7',
  '#FC9BB3',
  '#685BC7',
  '#98A4AE',
  '#25282A',
  '#FFB81C',
  '#FF00FF',
  '#5D6D7E',
  '#005F69',
  '#40D4B2',
  '#C38A19',
  '#DC55CE',
  '#2B2421',
];

export const PUCK_COLORS_NAME_PAIR = {
  '#00AB84': 'green',
  '#DA291C': 'red',
  '#FF6900': 'orange',
  '#69B3E7': 'blue',
  '#FC9BB3': 'pink',
  '#685BC7': 'purple',
  '#98A4AE': 'grey',
  '#25282A': 'black',
  '#FFB81C': 'yellow',
  '#FF00FF': 'magenta',
  '#5D6D7E': 'greyblue',
  '#005F69': 'lagoon',
  '#40D4B2': 'cyan',
  '#C38A19': 'auric',
  '#DC55CE': 'orchid',
  '#2B2421': 'autocaption',
};
