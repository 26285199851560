import { Injectable } from '@angular/core';

import { first } from 'rxjs/operators';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AccountService } from '@app/services/account.service';

import {
  SpeakseeAuthService,
  UserModel,
  UserTokenModel,
} from '@speaksee/speaksee-angular-core';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private authService: SpeakseeAuthService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // Avoid >1 reloads from users using qr auth mode.
    if (
      sessionStorage.getItem('auth_mode') === 'qr' &&
      +sessionStorage.getItem('session_counter') >= 1
    ) {
      await this.accountService.logout();
    }

    // Try to login with QR code if it exists
    const signInCode = route.queryParamMap.get('signInCode');
    if (signInCode) {
      try {
        await this.accountService.logout();
        await this.accountService.loginByQRCode(signInCode).toPromise();
        await this.router.navigateByUrl(route.url.join('/'), {
          replaceUrl: true,
        });
      } catch (e) {
        console.error(e);
        await this.router.navigateByUrl('/403');
        return false;
      }
    }

    // Try to login with token
    const userToken = this._checkIfRouteHasUserToken(route);
    if (userToken) {
      this.authService.updateUser(userToken);

      await this.router.navigateByUrl(route.url.join('/'), {
        replaceUrl: true,
      });
    }

    const user = this.accountService.userValue;
    if (!user?.token) {
      return this._redirectToLogin(state.url);
    }

    // logout if (access|refresh)token has expired
    if (this._isTokenExpired(user.token)) {
      this.accountService.logout();
      return false;
    }

    try {
      this.accountService.refresh().pipe(first()).toPromise();
    } catch {
      return this._redirectToLogin(state.url);
    }

    return true;
  }

  // --------------------------------------------------------------------------
  // @ Private methods
  // --------------------------------------------------------------------------

  /**
   * Checks if the given user access|refresh token have expired.
   *
   * @param token - The user token to check.
   * @returns True if the token has expired, false otherwise.
   */
  private _isTokenExpired(token: UserTokenModel): boolean {
    const now = new Date().getTime();
    return token.accessExpireAt < now && token.refreshExpireAt < now;
  }

  /**
   * Checks if the given route has a user token in its query parameters.
   *
   * @param route - The route to check for a user token.
   * @returns The parsed user token object if it exists in the route's query
   *          parameters, or undefined otherwise.
   */
  private _checkIfRouteHasUserToken(
    route: ActivatedRouteSnapshot
  ): UserModel | null {
    const token = route.queryParamMap.get('token');
    if (!token) {
      return null;
    }
    try {
      const decodedToken = JSON.parse(atob(token));
      if (Object.keys(decodedToken).length) {
        return decodedToken;
      }
    } catch (e) {
      console.error('Failed to parse base64 token:', e);
    }
  }

  /**
   * Redirects the user to the login page with a return URL query parameter.
   *
   * @param redirectURL - The URL to redirect the user to after successful
   *                      login.
   * @returns A Promise that resolves to a boolean indicating whether the
   *          navigation was successful.
   */
  private _redirectToLogin(redirectURL?: string): Promise<boolean> {
    const _params = {};
    if (redirectURL) {
      _params['returnUrl'] = redirectURL;
    }

    return this.router.navigate(['/login'], _params);
  }
}
