export class Microphone {
  public active: boolean;
  private _name: string;
  constructor(
    name: string,
    public color: string,
    public readonly id: number,
    private fallbackName?: string
  ) {
    this._name = name;
  }

  get name() {
    let name = this._name;
    if (name && !isNaN(+name)) {
      return '' + name;
    }
    if (!name) {
      name = this.fallbackName;
    }
    return name.charAt(0).toUpperCase() + name.substr(1);
  }

  set name(name: string) {
    this._name = name;
  }
}
