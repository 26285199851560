import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Directive({
  selector: '[appDynamicTheme]',
})
export class DynamicThemeDirective {
  private theme: string;
  constructor(
    el: ElementRef,
    renderer: Renderer2,
    settingsService: SettingsService
  ) {
    settingsService.theme$.subscribe((x) => {
      renderer.removeClass(el.nativeElement, this.theme);
      renderer.addClass(el.nativeElement, x);
      this.theme = x;
    });
  }
}
