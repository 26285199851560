<div class="microphone">
  <i
    class="fa fa-microphone"
    [style.color]="microphone.color"
    *ngIf="microphone.name.toLowerCase() !== 'autocaption'"
  ></i>
  <i
    class="fa fa-laptop"
    [style.color]="microphone.color"
    *ngIf="microphone.name.toLowerCase() === 'autocaption'"
  ></i>
  <div class="microphone__indicator">
    <span *ngIf="showText" appSpeechBubbleText>
      {{ microphone.name }}
    </span>
  </div>
</div>
