import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Banner } from './shared/models/app.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public globalBanners: Observable<Banner[]>;

  constructor(
    public afAuth: AngularFireAuth,
    firestore: AngularFirestore,
    translate: TranslateService
  ) {
    translate.setDefaultLang('en');

    this.globalBanners = firestore
      .collection<Banner>('global-banners', (ref) =>
        ref.where('enabled', '==', true)
      )
      .valueChanges();
  }
}
