<div mat-dialog-title>
  <div class="title-icon">
    <img src="assets/svg/personalise.svg" alt="" />
  </div>
  <div class="title-text">Display</div>
</div>
<mat-dialog-content class="mat-typography">
  <mat-slide-toggle
    (change)="toggleMetadata()"
    [checked]="(showMessageMetadata$ | async) === false"
    class="toggle"
  >
    Compact Message Mode
  </mat-slide-toggle>
  <br />
</mat-dialog-content>
<div class="color-selector" *ngIf="this.kit | async">
  <h5 class="selector-heading">Color Selector</h5>
  <mat-accordion multi>
    <mat-expansion-panel
      *ngFor="let mic of (this.kit | async).microphones; index as i"
      class="expansion-panel-container"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="expansion-panel">
          <i
            class="fa fa-microphone mic-icon"
            [style.color]="mic.color_hex"
          ></i>
        </mat-panel-title>
        <mat-panel-description> Change Settings </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Panel Content -->
      <ng-template matExpansionPanelContent>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            matInput
            type="text"
            #form="ngModel"
            pattern="[a-zA-Z0-9 ]*"
            maxlength="25"
            [(ngModel)]="mic.name"
            (ngModelChange)="
              form.valid &&
                micSettingsChanged(mic.mic_id, mic.color_hex, mic.name)
            "
            [ngModelOptions]="{ updateOn: 'blur' }"
          />
          <mat-error *ngIf="form.invalid"> Invalid microphone name </mat-error>
        </mat-form-field>
        <mat-divider></mat-divider>
        <div class="color-panel">
          <div
            *ngFor="let color of colorList"
            [style.transform]="
              color === mic.color_hex ? 'scale(1.2)' : 'scale(1)'
            "
            [style.opacity]="color === mic.color_hex ? '1' : '0.2'"
          >
            <button (click)="micSettingsChanged(mic.mic_id, color, mic.name)">
              <div class="color-icon" [style.backgroundColor]="color"></div>
            </button>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
