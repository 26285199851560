import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import { first, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { EncryptionService } from '@app/modules/encryption/encryption.service';
import { License } from '@app/modules/license/license.types';

export interface SessionResponse {
  db: string;
  token: string;
}

export interface StartSessionRequest {
  serial: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public_key: string;
  uuid: string;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private counter: number = +sessionStorage.getItem('session_counter');
  private url = `${environment.speaksee.authUrl}/v1`;

  constructor(
    private $http: HttpClient,
    private auth: AngularFireAuth,
    private encryptionService: EncryptionService
  ) {}

  /**
   * start a new transcription session for the selected kit
   *
   * @param license license of the kit
   * @returns session response as observable
   */
  startSession(license: License): Observable<SessionResponse> {
    sessionStorage.setItem('session_counter', `${++this.counter}`);
    let type = 'hub';
    const request = {
      serial: license?.serial ?? license?.serials[0],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      public_key: license.encryption?.public_key
        ? this.encryptionService.encodeToHex(license.encryption.public_key)
        : '',
      uuid: license?.encryption?.uuid ?? '',
    } as StartSessionRequest;

    if (license.type === 'autocaption') {
      type = 'autocaption';
    }

    return this.$http
      .post<SessionResponse>(`${this.url}/${type}/token`, request)
      .pipe(
        switchMap(async (response) => {
          // skip login if already logged in
          if (await this.auth.user.pipe(first()).toPromise()) {
            return;
          }
          return this.auth
            .setPersistence('none')
            .then(() => this.auth.signInWithCustomToken(response.token))
            .then(() => response);
        })
      );
  }
}
