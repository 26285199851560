import { Component, Input, OnInit } from '@angular/core';
import { Banner } from '@app/shared/models/app.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent implements OnInit {

  @Input() banner: Banner;
  public langauge: string;
  public isOpen = true;

  constructor(translate: TranslateService) {
    this.langauge = translate.getDefaultLang();
  }

  ngOnInit(): void {
  }

  close(){
    this.isOpen = false;
  }

}
