<div class="container">
  <div class="content">
    <h1>403</h1>
    <h2>Access Forbidden</h2>
    <img src="assets/logo.svg" />
    <h4 style="line-height: 100px">
      Sorry, you do not have permission to access this page.
    </h4>
  </div>
</div>
