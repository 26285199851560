import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable } from 'rxjs';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss'],
})
export class DictionaryComponent implements OnInit {
  public dictionArray: any[] = [];

  private collection = this.db.collection(
    'devices/JDNcU83pZ6lLWXDHTi6u/dictionary'
  );
  private collectionObservable: Observable<any[]>;

  constructor(private db: AngularFirestore) {}

  ngOnInit() {
    this.getDictionary();
  }

  public add(input: string) {
    this.collection.add({ word: input, time: new Date() });
  }

  public remove(word: string) {
    console.log('removing', word);
  }

  public capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public sortedArray(array: any[]) {
    return orderBy(array, 'time', 'desc');
  }

  private getDictionary() {
    this.collectionObservable = this.collection.valueChanges();
    this.collectionObservable.subscribe((dictionary: any) => {
      this.dictionArray = dictionary;
    });
  }
}
