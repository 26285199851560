/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  IRemoteSettings,
  SettingsService,
} from '@app/services/settings.service';
import {
  LanguageSelection,
  LanguageService,
} from '@app/services/language.service';

import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { Language } from '@app/shared/models/app.model';
import { UpdateMicrophoneSettingsRequest } from '@app/services/settings-api.service';

@Component({
  selector: 'app-language-setting-dialog',
  templateUrl: './language-setting-dialog.component.html',
  styleUrls: ['./language-setting-dialog.component.scss'],
})
export class LanguageSettingDialogComponent implements OnInit {
  public updateMode: 'all' | 'each' = 'all';

  public languages: Language[];
  public currentLanguage$: Observable<LanguageSelection>;
  public kit: Observable<IRemoteSettings>;

  public localLanguageOut$: Observable<string>;
  public isQRAuthMode = sessionStorage.getItem('auth_mode') === 'qr';

  private micUpdates: Omit<UpdateMicrophoneSettingsRequest, 'event'> = {
    microphones: [],
  };

  constructor(
    public languageService: LanguageService,
    private settings: SettingsService,
    private dialogRef: MatDialogRef<LanguageSettingDialogComponent>,
    private dialogService: MatDialog
  ) {
    this.localLanguageOut$ = settings.localLanguageOut$;
    this.currentLanguage$ = languageService.currentLanguage$;
    this.kit = this.settings.getRemoteSetSettings();
  }

  ngOnInit() {
    this.languages = this.languageService.languages
      .filter((x) => x.iconClass !== '')
      .sort((a, b) => {
        const textA = a.exonym.toUpperCase();
        const textB = b.exonym.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .sort((a, b) => {
        const boolA = a.isVerified;
        const boolB = b.isVerified;
        return boolA && !boolB ? -1 : !boolA && boolB ? 1 : 0;
      });
  }

  /**
   * when the select mode changes
   */
  public modeChanged() {
    this.micUpdates.microphones = [];
  }

  // DRY: Found similar logic in `languageOutChanged`
  public async languageInChanged(micId, language) {
    let microphones = [micId];

    // Update all mics when all is selected
    if (this.updateMode === 'all') {
      microphones = (await this.kit.pipe(take(1)).toPromise()).microphones.map(
        (x) => x.mic_id
      );
    }

    for (const id of microphones) {
      const mic = this.micUpdates.microphones.find((x) => x.mic_id === id);
      if (!mic) {
        this.micUpdates.microphones.push({
          mic_id: id,
          language_in: language.languageCode,
        } as any);
      } else {
        mic.language_in = language.languageCode;
      }
    }
  }

  // DRY: Found similar logic in `languageInChanged`
  // TODO: Copy/Change this for mic color selector
  public async languageOutChanged(micId, language) {
    this.settings.changeLocalLanguageOut(language.languageCode);
    let microphones = [micId];

    // update all mics when all is selected
    if (this.updateMode === 'all') {
      microphones = (await this.kit.pipe(take(1)).toPromise()).microphones.map(
        (x) => x.mic_id
      );
    }

    for (const id of microphones) {
      const mic = this.micUpdates.microphones.find((x) => x.mic_id === id);
      if (!mic) {
        this.micUpdates.microphones.push({
          mic_id: id,
          language_out: language.languageCode,
        } as any);
      } else {
        mic.language_out = language.languageCode;
      }
    }
  }

  public async save() {
    if (this.micUpdates.microphones.length === 0) {
      this.dialogRef.close();
      return;
    }

    try {
      await this.settings
        .updateMicSettings(this.micUpdates)
        .pipe(take(1))
        .toPromise();
      this.dialogRef.close();
    } catch (error) {
      this.dialogService.open(ErrorDialogComponent, {
        data: { error },
      });
    }
  }
}
