export const environment = {
  version: '1.4.1',
  versionName: 'Catamaran',
  commitHash: 'c3465ce',
  production: false,
  firebase: {
   apiKey: 'AIzaSyCoSALX2fUD54wPpHX2iaZqsP9B9X6zzjQ',
    authDomain: 'speakseemvp.firebaseapp.com',
    databaseURL: 'https://speakseemvp.firebaseio.com',
    projectId: 'speakseemvp',
    storageBucket: 'speakseemvp.appspot.com',
    messagingSenderId: '353492585696',
    appId: '1:353492585696:web:6764e53e672bc07ce314',
    documentName: 'Hub1-qa-master'
  },
  speaksee: {
    releaseNumber: 'master',
    authUrl: 'https://authenticate.speaksee.ai',
    apiUrl: 'https://api.speaksee.ai',
    mySpeaksee: 'https://my.speaksee.ai'
  }
};
