import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { AccountService } from '@app/services/account.service';
import { DeveloperDialogComponent } from '@app/components/developer-dialog/developer-dialog.component';
import { DisplaySettingsComponent } from '@app/components/display-settings/display-settings.component';
import { LanguageSettingDialogComponent } from '@app/components/language-setting-dialog/language-setting-dialog.component';
import { Message } from '@app/shared/models/app.model';
import { Microphone } from '@app/shared/models/microphone.model';
import { SettingsComponent } from '@app/components/settings/settings.component';
import { ShareDialogComponent } from '@app/components/share-dialog/share.component';
import { SpeechService } from '@app/services/speech.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('header', { static: false }) el: ElementRef;
  @Input() noButtons: boolean;
  @Input() enableLogout: boolean;

  showDownloadButton = true;
  showLanguageButton = true;
  showShareButton = true;
  showDisplayButton = true;

  inAppBrowser = !!(
    window['cordova_iab'] ?? window['webkit']?.messageHandlers['cordova_iab']
  );

  private readonly showAboutThreshold = 10;
  private showAboutClickCount = 0;

  constructor(
    // private renderer: Renderer2,
    private router: Router,
    private auth: AccountService,
    private speechService: SpeechService,
    private dialog: MatDialog // private activatedRoute: ActivatedRoute,
  ) {}

  // @HostListener("window:scroll", [])
  // onWindowScroll() {
  //   if(window.scrollY > 0) {
  //     this.renderer.addClass(this.el.nativeElement, 'small-header');
  //   } else {
  //     this.renderer.removeClass(this.el.nativeElement, 'small-header');
  //   }
  // }

  ngOnInit() {
    // NOTE: Hide share, languages and save
    if (sessionStorage.getItem('auth_mode') === 'qr') {
      // this.noButtons = true;
      // this.enableLogout = false;
      this.showDownloadButton =
        this.showLanguageButton =
        this.showShareButton =
        this.showDisplayButton =
          false;
    }
  }

  async logout() {
    if (this.inAppBrowser) {
      const target =
        window['cordova_iab'] ??
        window['webkit']?.messageHandlers['cordova_iab'];
      target?.postMessage(JSON.stringify({ command: 'clse' }));
    }

    await this.auth.logout();
    await this.router.navigate(['/login']);
  }

  openLanguageSettings() {
    this.dialog.open(LanguageSettingDialogComponent, {
      maxWidth: '400px',
      width: '100%',
    });
  }

  openMenuAccessibilitySettings() {
    this.dialog.open(SettingsComponent, {
      maxWidth: '600px',
      width: '80vw',
    });
  }

  // NOTE: Not in use
  // openMenuPersonalize() {
  //   this.dialog.open(PersonalizeDialogComponent, {
  //     maxWidth: '400px',
  //     width: '100%',
  //   });
  // }

  openMenuDisplaySettings() {
    this.dialog.open(DisplaySettingsComponent, {
      maxWidth: '400px',
      width: '100%',
      panelClass: 'share-dialog',
    });
  }

  openDialogShare() {
    this.dialog.open(ShareDialogComponent, {
      maxWidth: '400px',
      width: '100%',
      // data: {
      //   env: this.activatedRoute.snapshot.data.app,
      // }
    });
  }

  public async downloadTranscript() {
    const mics = await this.speechService.activeMics$.pipe(take(1)).toPromise();
    if (!mics.length) {
      alert('No transcripts yet. Start talking first.');
      return;
    }

    this.speechService.messages$
      .pipe(take(1))
      .subscribe((messages: Message[]) => {
        const text = messages
          .map((message: Message) => {
            const mic = mics.find(
              (_mic: Microphone) => message.micId === _mic.id
            );
            let micName = 'Microphone ' + message.micColor.name;
            if (mic) {
              micName = isNaN(+mic.name)
                ? mic.name
                : 'Microphone ' + message.micColor.name + ': ';
            }

            const result = `${micName} - ${this.formatTime(
              message.timestamp
            )}:\n${message.message}\n\n`;

            return result;
          })
          .join('\n');

        this.downloadTextFile(
          `Transcript-${this.formatDate(new Date(messages[0].timestamp))}`,
          text
        );
      });
  }

  clickLogo() {
    this.showAboutClickCount++;
    if (this.showAboutClickCount >= this.showAboutThreshold) {
      this.dialog.open(DeveloperDialogComponent);
      this.showAboutClickCount = 0;
    }
  }

  private formatTime(timestamp: any) {
    if (timestamp) {
      const date = new Date(timestamp);
      const hours = date.getHours();
      const minutes = date.getMinutes();

      let stringMinutes = String(minutes);

      if (minutes < 10) {
        stringMinutes = `0${minutes}`;
      }

      return `${hours}:${stringMinutes}`;
    }
  }

  private formatDate(timestamp: Date) {
    const date = new Date(timestamp);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  private downloadTextFile(fileName: string, text: string) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
