<app-header
  [enableLogout]="
    this.allLicenses?.length === 1 || this.kitLicenses?.length === 1
  "
></app-header>

<app-microphone-bar
  *ngIf="(microphones$ | async)?.length"
  [microphones]="microphones$ | async"
>
</app-microphone-bar>
<div
  class="messages-wrapper"
  #scrollView
  appSpeechViewportWidth
  (scroll)="onWindowScroll()"
>
  <!-- TODO: Look into why this works -->
  <app-scroll-back-down-button
    [messageLength]="(messages$ | async)?.length"
    (childScrollToBottom)="scrollToBottom()"
    [childIsAtBottom]="isAtBottom.bind(this)"
  ></app-scroll-back-down-button>
  <div
    *ngIf="!(messages$ | async)?.length"
    class="speech-component__no-data text-center"
  >
    <span class="speech-component__no-data__text">
      <strong>No messages received yet.</strong><br />
      Turn on your Microphone Kit and start talking.
    </span>
    <div class="speech-component__no-data__image">
      <img src="assets/placeholder.svg" />
    </div>
    <span class="speech-component__no-data__text">
      NOTE: Ensure the microphone points towards the mouth,<br />
      articulate clearly and speak audibly (loud enough).
    </span>
  </div>

  <app-speech-bubble
    *ngFor="let message of messages$ | async; let i = index; trackBy: trackByFn"
    [message]="message"
    [prevTimestamp]="(messages$ | async)[i - 1]?.updated_at"
  ></app-speech-bubble>
</div>
