/* eslint-disable @typescript-eslint/naming-convention */

import { EncryptionService } from '@app/modules/encryption/encryption.service';
import { Message } from './app.model';

export class ParsedMessage {
  documentID: string;
  micId: number;
  is_final: boolean;
  confidence: number;
  timestamp: number;
  created_at: Date;
  micColor: {
    hex: string;
    name: string;
  };
  updated_at: Date;
  language: string;
  fullMessage: string;
  encryptedMessage: string;
  is_encrypted = false;
  device?: 'autocaption' | 'microphonekit';
  changeHistory: {
    is_final: boolean;
    message: string;
    updated_at: Date;
    is_encrypted: boolean;
  }[];

  constructor(
    newMessage: Message,
    private onlyChangeOnFinal = false,
    private encryptionService: EncryptionService
  ) {
    this.confidence = newMessage.confidence;
    this.documentID = newMessage.documentID;
    this.is_final = newMessage.is_final;
    this.language = newMessage.language;
    this.micColor = newMessage.micColor;
    this.micId = newMessage.micId;
    this.timestamp = newMessage.timestamp;
    this.created_at = newMessage.created_at;
    this.updated_at = new Date(newMessage.updated_at ?? newMessage.created_at);

    this.fullMessage = newMessage.message;
    if (newMessage.is_encrypted) {
      this.encryptedMessage = newMessage.message;
      this.fullMessage = this.encryptionService.decrypt(newMessage.message);
    }

    this.changeHistory = [
      {
        // message: newMessage.message,
        message: this.fullMessage,
        updated_at: this.created_at,
        is_final: this.is_final,
        is_encrypted: newMessage.is_encrypted,
      },
    ];
  }

  /**
   * Retrieves the messages from the change history and returns them as an
   * array of Message objects.
   *
   * @returns An array of Message objects.
   */
  public getMessages(): Message[] {
    return this.changeHistory.map((msg) => ({
      updated_at: msg.updated_at || this.created_at,
      is_final: msg.is_final,
      micId: this.micId,
      message: msg.message,
      confidence: this.confidence,
      timestamp: this.timestamp,
      created_at: this.created_at,
      micColor: this.micColor,
      language: this.language,
      documentID: this.documentID,
      is_encrypted: msg.is_encrypted,
    }));
  }

  public setFinal() {
    this.is_final = true;
    this.changeHistory[this.changeHistory.length - 1].is_final = true;
  }

  /**
   * Update all the internal messages with new data.
   * Update add the new message to the history of the parsedMessage
   *
   * @param messageUpdate new message to be added to the parsedMessage
   */
  public addNewChange(messageUpdate: Message) {
    // Don't add the same message twice
    const compareSource = messageUpdate.is_encrypted
      ? this.encryptedMessage
      : this.fullMessage;

    if (compareSource.trim() === messageUpdate.message.trim()) {
      return;
    }

    // if message is encrypted decrypt
    if (messageUpdate.is_encrypted) {
      this.encryptedMessage = messageUpdate.message;
      messageUpdate.message = this.encryptionService.decrypt(
        messageUpdate.message
      );
    }

    this.updated_at = new Date(messageUpdate.updated_at);

    this.updatePreviousSentences(messageUpdate);
    this.updateMessageHistory(messageUpdate);

    this.fullMessage = messageUpdate.message;

    if (messageUpdate.is_final) {
      this.setFinal();
    }
  }

  private updatePreviousSentences(newMessage: Message) {
    if (this.onlyChangeOnFinal && !newMessage.is_final) {
      return;
    }

    let originalMessageWordCount = 0;
    for (let index = 0; index < this.changeHistory.length; index++) {
      const prev = this.changeHistory[+index - 1];
      const current = this.changeHistory[index];
      const newMessageWordCount = current.message.split(' ').length;

      // Calculate previous message word count
      if (prev) {
        originalMessageWordCount += prev.message.split(' ').length;
      }

      // Add only the new words to the current message
      current.message = newMessage.message
        .split(' ')
        .slice(
          originalMessageWordCount,
          originalMessageWordCount + newMessageWordCount
        )
        .join(' ');
    }
  }

  private updateMessageHistory(newMessage: Message) {
    const lastUpdateWordCount = this.fullMessage.split(' ').length;
    const newWordSplit = newMessage.message.split(' ');

    if (newWordSplit.slice(lastUpdateWordCount).join(' ').trim() !== '') {
      this.changeHistory.push({
        is_final: newMessage.is_final,
        message: newWordSplit.slice(lastUpdateWordCount).join(' '),
        updated_at: newMessage.updated_at,
        is_encrypted: newMessage.is_encrypted,
      });
    }
  }
}
