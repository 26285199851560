<div mat-dialog-title>
  <div class="title-icon">
    <img src="assets/svg/accessibility.svg" alt="" />
  </div>
  <div class="title-text">Accessibility</div>
</div>
<mat-dialog-content class="mat-typography">
  <form #f="ngForm">
    <div class="form-group">
      <label for="font-size">Font Size</label>
      <div class="row font-size-slider">
        <div class="col-2 font-size-slider__small">A</div>
        <div class="col-8 font-size-slider__input">
          <ng5-slider
            [value]="fontSize$ | async"
            (valueChange)="changeFontSize($event)"
            [options]="sliderOptions"
          ></ng5-slider>
        </div>
        <div class="col-2 font-size-slider__big">A</div>
      </div>
      <label for="font-size">Text width</label>
      <div class="row font-size-slider">
        <div class="col-2 font-size-slider__small">
          <mat-icon>width_full</mat-icon>
        </div>
        <div class="col-8 font-size-slider__input">
          <ng5-slider
            [value]="viewportWidth$ | async"
            (valueChange)="changeViewportWidth($event)"
            [options]="sliderOptions"
          ></ng5-slider>
        </div>
        <div class="col-2 font-size-slider__big">
          <mat-icon>width_wide</mat-icon>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="theme">Theme</label>
      <select
        [(ngModel)]="currentTheme"
        name="theme"
        (ngModelChange)="changeTheme()"
        id="theme"
        class="custom-select"
      >
        <option *ngFor="let theme of themes" [value]="themeList[theme]">
          {{ theme }}
        </option>
      </select>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
