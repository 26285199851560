import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Component, TemplateRef, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LoginTranslations } from '@speaksee/speaksee-angular-core/src/lib/components/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild('emailDialog', { static: true }) emailDialog: TemplateRef<any>;
  notifier = new Subject();

  email = '';
  returnUrl = '';

  loginLabels: Observable<LoginTranslations>;
  translationTrigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  readonly requestEmail = `${environment.speaksee.mySpeaksee}/account/email-confirmation`;
  readonly resetPasswordURL = `${environment.speaksee.mySpeaksee}/account/password-reset-request`;
  readonly registerURL = `${environment.speaksee.mySpeaksee}/account/register`;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    if (this.translate.getLangs().length) {
      this.translationTrigger.next(true);
    }

    this.loginLabels = merge(
      this.translationTrigger,
      this.translate.onDefaultLangChange
    ).pipe(
      filter((x) => !!x),
      map(() => ({
        signInText: this.translate.instant('login.sign_in'),
        emailLabel: this.translate.instant('login.email_label'),
        passwordLabel: this.translate.instant('login.password_label'),
        error: {
          email: this.translate.instant('login.error.email'),
          password: this.translate.instant('login.error.password'),
          emailNotActivated: this.translate.instant(
            'login.error.email_not_activated'
          ),
          invalidUserOrPassword: this.translate.instant(
            'login.error.invalid_user_or_password'
          ),
          unknownError: this.translate.instant('login.error.unknown_error'),
        },
      }))
    );
  }

  async onLogin() {
    // console.log(this.returnUrl);
    if (this.returnUrl) {
      await this.router.navigateByUrl(this.returnUrl);
    } else {
      await this.router.navigate(['/microphone-kits']);
    }
  }

  onUnverified(email: string) {
    this.email = email;
    this.dialog.open(this.emailDialog);
  }

  onError(error: any) {
    console.log(error);
  }
}
