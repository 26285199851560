// import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { Router, RouterModule, Routes } from '@angular/router';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Ng5SliderModule } from 'ng5-slider';
import { QRCodeModule } from 'angularx-qrcode';

import { from, Observable } from 'rxjs';

import * as Sentry from '@sentry/angular-ivy';

import { AnonGuard } from './guards/anon.guard';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';
import { DictionaryComponent } from './pages/speech/dictionary/dictionary.component';
import { DisplaySettingsComponent } from './components/display-settings/display-settings.component';
import { DynamicThemeDirective } from './directives/dynamic-theme.directive';
import { environment } from '../environments/environment';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { HubSelectorComponent } from './pages/hub-selector/hub-selector.component';
import { LanguageSettingDialogComponent } from './components/language-setting-dialog/language-setting-dialog.component';
import { LiveTranslatePipe } from './pipes/live-translate.pipe';
import { LoginComponent } from './pages/login/login.component';
import { MicrophoneBarComponent } from './components/microphone-bar/microphone-bar.component';
import { MicrophoneComponent } from './components/microphone/microphone.component';
import { NoSleepService } from './services/no-sleep.service';
import { SpeechBubbleTextDirective } from './directives/speech-bubble-text.directive';
// import { PersonalizeDialogComponent } from './components/personalize-dialog/personalize-dialog.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShareDialogComponent } from './components/share-dialog/share.component';
import { ViewportWidthDirective } from './directives/speech-viewport.directive';

import {
  CustomSnackbarComponent,
  ScrollBackDownButtonComponent,
} from './components/scroll-back-down-button/scroll-back-down-button.component';

import { SpeechBubbleComponent } from './pages/speech/speech-bubble/speech-bubble.component';
import { SpeechComponent } from './pages/speech/speech.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { TranslateService } from './services/translate.service';

import {
  SpeakseeAngularCoreModule,
  SpeakseeLoginModule,
} from '@speaksee/speaksee-angular-core';
import { DeveloperDialogComponent } from './components/developer-dialog/developer-dialog.component';
import { Error403Component } from './pages/error/403/403.component';

import { InformationBannerComponent } from './components/information-banner/information-banner.component';
import { LicensesResolver } from './modules/license/license.resolver';
import { SessionResolver } from './modules/session/session.resolver';

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}
// DEPRECATION NOTE:
// Class and injection token guards and resolvers are deprecated.
// the interfaces like CanActivate, CanDeactivate, etc. will be deleted in a
// future release of Angular
//
// More info:
// https://angular.io/guide/deprecations#router-class-and-injectiontoken-guards-and-resolvers
const appRoutes: Routes = [
  {
    path: 'speech/:id',
    component: SpeechComponent,
    resolve: {
      session: SessionResolver,
    },
    canActivate: [AuthGuard],
  },
  { path: 'speech', component: SpeechComponent },
  { path: 'dictionary', component: DictionaryComponent },
  { path: 'login', component: LoginComponent, canActivate: [AnonGuard] },
  {
    path: 'microphone-kits',
    component: HubSelectorComponent,
    resolve: {
      licenses: LicensesResolver,
    },
    canActivate: [AuthGuard],
  },
  { path: '403', component: Error403Component, canActivate: [AnonGuard] },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  declarations: [
    AppComponent,
    CustomSnackbarComponent,
    DictionaryComponent,
    DisplaySettingsComponent,
    SpeechBubbleTextDirective,
    DynamicThemeDirective,
    ErrorDialogComponent,
    HeaderComponent,
    HubSelectorComponent,
    LanguageSettingDialogComponent,
    LiveTranslatePipe,
    LoginComponent,
    MicrophoneBarComponent,
    MicrophoneComponent,
    // PersonalizeDialogComponent,
    ScrollBackDownButtonComponent,
    SettingsComponent,
    ShareDialogComponent,
    SpeechBubbleComponent,
    SpeechComponent,
    Error403Component,
    DeveloperDialogComponent,
    ViewportWidthDirective,
    InformationBannerComponent,
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    ExperimentalScrollingModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    Ng5SliderModule,
    QRCodeModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    ScrollingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
    //
    SpeakseeAngularCoreModule.forRoot({
      apiBaseUrl: environment.speaksee.apiUrl,
      services: {
        auth: {
          version: 'v1',
        },
        order: {
          version: 'v1',
        },
        settings: {
          version: 'v1',
        },
      },
    }),
    SpeakseeLoginModule,
  ],
  providers: [
    TranslateService,
    NoSleepService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        display: { dateInput: 'DD/MM/YYYY' },
      },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
