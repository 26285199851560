import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import {
  SpeakseeAuthService,
  UserModel,
  UserTokenModel,
} from '@speaksee/speaksee-angular-core';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private router: Router,
    private firebaseAuth: AngularFireAuth,
    private speakseeAuthService: SpeakseeAuthService
  ) {
    const userObject = localStorage.getItem('token');

    if (userObject) {
      try {
        this.speakseeAuthService.updateUser(JSON.parse(userObject));
      } catch {}
    }

    this.speakseeAuthService.user$.subscribe((user) => {
      if (user) {
        localStorage.setItem(
          'token',
          JSON.stringify({
            token: user?.token,
          })
        );
      }
    });
  }

  public get userValue(): UserModel | undefined {
    return this.speakseeAuthService.userValue;
  }

  public setEmail(email: string): void {
    let user = this.userValue;

    if (!user) {
      user = {};
    }

    user.email = email;
    this.speakseeAuthService.updateUser(user);
  }

  /**
   * Logs in a user with the provided email and password and an optional mode
   * (qr|password).
   *
   * @param email The email of the user.
   * @param password The password of the user.
   * @param mode Optional. The authentication mode to use. Defaults to
   *            'password'.
   * @returns An Observable of UserTokenModel.
   */
  login(
    email: string,
    password: string,
    mode?: string
  ): Observable<UserTokenModel> {
    return this.speakseeAuthService.login(email, password).pipe(
      concatMap((token) => {
        localStorage.setItem(
          'token',
          JSON.stringify({
            token,
          })
        );

        // IMPORTANT:
        // Authentication mode QR is used when a user logs in using a QR,
        // QR codes can be shared and used by other users to log in.
        sessionStorage.setItem('auth_mode', mode === 'qr' ? mode : 'password');

        return of(token);
      })
    );
  }

  /**
   * Logs in a user using a QR code.
   *
   * @param signInCode The QR code to use for login.
   * @returns An observable that emits a UserTokenModel object upon successful
   *          login.
   * @throws An error if the provided expiration time has already passed.
   */
  loginByQRCode(signInCode: string): Observable<UserTokenModel> {
    const [username, password, expiresAt] = this._deobfuscate(signInCode);
    if (expiresAt && +expiresAt <= new Date().getTime()) {
      return throwError(
        'Unable to login. The provided expiration time has already passed.'
      );
    }
    return this.login(username, password, 'qr');
  }

  /**
   * Logs out the current user by removing their token from local storage,
   * clearing the session storage, and redirecting to the login page.
   *
   * This method also signs out the user from Firebase.
   *
   * @returns Returns false to prevent default link behavior.
   */
  async logout(): Promise<void> {
    // remove user from local storage and set current user to null
    localStorage.removeItem('token');
    sessionStorage.clear();

    await this.speakseeAuthService.logout();
    await this.firebaseAuth.signOut();
  }

  refresh(): Observable<any> {
    return this.speakseeAuthService.refresh();
  }

  /**
   * NOTE: The following `_(de)obfuscate` methods could be moved to a higher
   * level, because they are not specific to the account service and they are
   * generic enough to be reused somewhere else.
   */

  /**
   * Obfuscate an array of strings or numbers by encoding them to base64,
   * reversing them and joining them by a separator.
   *
   * @param params - An array of strings or numbers to be obfuscated.
   * @returns The obfuscated string.
   */
  obfuscate(params: Array<string>): string {
    if (params.length === 1) {
      return btoa(params[0].toString().split('').reverse().join());
    }

    const args = [];
    params.forEach((param) => args.push(this.obfuscate([param])));

    const separator = 'ñ'; // ;)
    return btoa(args.join(separator).split('').reverse().join(''));
  }

  // --------------------------------------------------------------------------
  // @ Private methods
  // --------------------------------------------------------------------------

  /**
   * Deobfuscates a string by decoding it from base64, reversing it, splitting
   * it by a separator, and recursively deobfuscating each resulting parameter.
   *
   * @param str The string to deobfuscate.
   * @returns An array of deobfuscated strings.
   */
  private _deobfuscate(str: string): string[] {
    const separator = 'ñ'; // ;)
    const params = atob(str).split('').reverse().join('').split(separator);
    if (params.length === 1) {
      return [atob(str).split(',').reverse().join('')];
    }

    const args = [];
    params.forEach((param) => args.push(...this._deobfuscate(param)));

    return args;
  }
}
