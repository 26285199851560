import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Directive({
  selector: '[appSpeechBubbleText]',
})
export class SpeechBubbleTextDirective {
  constructor(el: ElementRef, renderer: Renderer2, settings: SettingsService) {
    // Update fond-size
    settings.fontSize$.subscribe((x: number) => {
      el.nativeElement.style.fontSize = `${1 + x / 100}em`;
    });

    // Update lang
    settings.localLanguageOut$.subscribe((language: string) => {
      renderer.setAttribute(el.nativeElement, 'lang', language);
    });
  }
}
