import { Buffer } from 'buffer';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import init, * as ecies from 'ecies-wasm';

import { Encryption } from './encryption.types';
import { IDBService } from '@app/services/indexeddb.service';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private engine: any;
  private publicKey = new BehaviorSubject<Uint8Array>(null);
  private privateKey = new BehaviorSubject<Uint8Array>(null);

  constructor(private indexedDB: IDBService) {
    this.engine = init('src/assets/wasm/ecies_wasm_bg.wasm');
  }

  get privateKey$(): Observable<Uint8Array> {
    return this.privateKey.asObservable();
  }

  get publicKey$(): Observable<Uint8Array> {
    return this.publicKey.asObservable();
  }

  /**
   * Converts a Uint8Array to a hexadecimal string representation.
   *
   * @param data The Uint8Array to be converted.
   * @returns The hexadecimal string representation of the input data.
   */
  encodeToHex(data: Uint8Array): string {
    return (
      Array.prototype.map
        // eslint-disable-next-line no-bitwise
        .call(data, (byte: any) => ('0' + (byte & 0xff).toString(16)).slice(-2))
        .join('')
    );
  }

  /**
   * Encrypts a message using the public key.
   *
   * @param message - The message to be encrypted.
   * @returns The encrypted message as a hexadecimal string.
   * @throws Error if no public key is found.
   */
  encrypt(message: string): string {
    const publicKey = this.publicKey.getValue();
    if (publicKey === null) {
      throw new Error('No publicKey key found');
    }

    return this.encodeUint8ArrayToHex(
      ecies.encrypt(publicKey, this.encode(message))
    );
  }

  /**
   * Decrypts the encoded data using the private key.
   *
   * @param encodedData The encoded data to be decrypted.
   * @returns The decrypted data as a string.
   * @throws Error if no private key is found.
   */
  decrypt(encodedData: string): string {
    // decode the hex string to Uint8Array
    const encryptedData = this.decodeHexToUint8Array(encodedData);

    if (this.privateKey.value === null) {
      throw new Error('No private key found');
    }

    return this.decode(ecies.decrypt(this.privateKey.value, encryptedData));
  }

  /**
   * Retrieves or creates encryption keys for the given serial number.
   * If the encryption keys are found in the indexedDB, they are returned.
   * Otherwise, new encryption keys are generated, stored in the indexedDB, and returned.
   *
   * @param serial The serial number for which to retrieve or create encryption keys.
   * @returns A Promise that resolves to an Encryption object containing the encryption keys.
   */
  async getOrCreateKeys(serial: string): Promise<Encryption> {
    let encryption = await this.indexedDB.get('keys', serial);
    if (encryption === undefined) {
      encryption = await this.generateKeys(serial);
      await this.indexedDB.add('keys', encryption);
    }

    return this.updateEncryptionKeys(encryption);
  }

  /**
   * Generates a new set of encryption keys.
   *
   * @returns A promise that resolves to an Encryption object containing the
   *          private key, public key, creation date, and UUID.
   */
  private async generateKeys(serial: string): Promise<Encryption> {
    return this.engine
      .then(() => {
        const [secretKey, publicKey] = ecies.generateKeypair();

        return {
          serial,
          uuid: crypto.randomUUID(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          private_key: secretKey,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          public_key: publicKey,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          created_at: new Date(),
        } as Encryption;
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  /**
   * Updates the encryption keys with the provided encryption object.
   *
   * @param encryption The encryption object containing the private and public
   *                   keys.
   */
  private updateEncryptionKeys(encryption: Encryption): Encryption {
    this.privateKey.next(encryption.private_key);
    this.publicKey.next(encryption.public_key);

    return encryption;
  }

  /**
   * Encodes the given string into a Uint8Array.
   *
   * @param data - The string to encode.
   * @returns The encoded Uint8Array.
   */
  private encode(data: string): Uint8Array {
    const encoder = new TextEncoder();
    return encoder.encode(data);
  }

  /**
   * Decodes the given Uint8Array into a string using TextDecoder.
   *
   * @param data - The Uint8Array to decode.
   * @returns The decoded string.
   */
  private decode(data: Uint8Array): string {
    return new TextDecoder().decode(data);
  }

  /**
   * Decodes a hexadecimal string into a Uint8Array.
   *
   * @param hex The hexadecimal string to decode.
   * @returns The Uint8Array representation of the decoded hexadecimal string.
   */
  private decodeHexToUint8Array(hex: string): Uint8Array {
    return new Uint8Array(Buffer.from(hex, 'hex'));
  }

  /**
   * Encodes a Uint8Array to a hexadecimal string.
   *
   * @param data The Uint8Array to encode.
   * @returns The hexadecimal string representation of the Uint8Array.
   */
  private encodeUint8ArrayToHex(data: Uint8Array): string {
    return Buffer.from(data).toString('hex');
  }
}
