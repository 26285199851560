import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Options } from 'ng5-slider';

import { SettingsService, Themes } from '../../services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  public fontSize$: Observable<number>;
  public viewportWidth$: Observable<number>;
  public themes: string[];
  public currentTheme: Themes;
  public themeList = Themes;
  public sliderOptions: Options = {
    // floor: -25,
    ceil: 125,
    animate: false,
    showTicks: true,
    step: 25,
  };
  constructor(
    private settingsService: SettingsService,
    public dialogRef: MatDialogRef<SettingsComponent>
  ) {
    this.settingsService.theme$
      .subscribe((x: Themes) => {
        this.currentTheme = x;
      })
      .unsubscribe();
    this.fontSize$ = this.settingsService.fontSize$;
    this.themes = this.settingsService.getAvailableThemes();
    this.viewportWidth$ = this.settingsService.viewportWidth$;
  }

  changeFontSize(size: number) {
    this.settingsService.changeFontSize(size);
  }

  changeTheme() {
    this.settingsService.changeTheme(this.currentTheme);
  }

  changeViewportWidth(width: number) {
    this.sliderOptions = {
      ...this.sliderOptions,
    };
    this.settingsService.changeViewportWidth(width);
  }
}
