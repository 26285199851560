import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { catchError, concatMap, map } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

import { Encryption } from '../encryption/encryption.types';
import { EncryptionService } from '../encryption/encryption.service';
import { License } from '../license/license.types';
import { SessionService } from '@app/services/session.service';
import { SettingsService } from '@app/services/settings.service';
import { SpeakseeOrderService } from '../license/license.service';

/**
 * Resolver for the session route.
 * This resolver is responsible for resolving the session data before
 * navigating to the session route.
 */
@Injectable({
  providedIn: 'root',
})
export class SessionResolver implements Resolve<License> {
  constructor(
    private router: Router,
    private orderService: SpeakseeOrderService,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private encryptionService: EncryptionService
  ) {}

  /**
   * Resolves the session data.
   *
   * @param route - The activated route snapshot.
   * @returns An observable of the resolved license.
   */
  resolve(route: ActivatedRouteSnapshot): Observable<License> {
    const licenseId = route.params.id;

    // we use concatMap instead of switchMap because we don't want premature cancellation of indexDB calls
    return this.orderService.getLicense(licenseId).pipe(
      concatMap((license: License) =>
        from(this.encryptionService.getOrCreateKeys(license.serial ?? license?.serials[0])).pipe(
          map((encryption: Encryption) => {
            license.encryption = encryption;
            return license;
          })
        )
      ),
      concatMap((license: License) => {
        if (license.type === 'microphonekit') {
          return this.settingsService
            .getRemoteSetSettings(license.id, true)
            .pipe(map(() => license));
        }
        return of(license);
      }),
      concatMap((license: License) =>
        this.sessionService.startSession(license).pipe(map(() => license))
      ),
      catchError((error: any) => {
        console.error(error);
        this.router.navigate(['/microphone-kits']);
        throw error;
      })
    );
  }
}
