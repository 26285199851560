import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import {
  HubOrderRegisterRequest,
  HubOrderRegisterResponse,
  HubSetLicenseOrder,
} from '@speaksee/speaksee-angular-core/src/lib/models';

import { EncryptionService } from '../encryption/encryption.service';
import { environment } from '@environments/environment';
import { License } from './license.types';

@Injectable({
  providedIn: 'root',
})
export class SpeakseeOrderService {
  private baseUrl;
  // private indexedDB: IDBService = new IDBService();

  private _licenses: BehaviorSubject<License[]> = new BehaviorSubject<
    License[]
  >([]);
  private _license: BehaviorSubject<License> = new BehaviorSubject<License>(
    null
  );

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {
    this.baseUrl = `${environment.speaksee.apiUrl}/v1/order`;
    this.encryptionService = encryptionService;
  }

  /**
   * Returns an observable of licenses.
   *
   * @memberof SpeakseeOrderService
   * @returns An observable of licenses.
   */
  get licenses$(): Observable<License[]> {
    return this._licenses.asObservable();
  }

  /**
   * Returns an observable that emits the current license.
   *
   * @memberof SpeakseeOrderService
   * @returns An observable of type License.
   */
  get license$(): Observable<License> {
    return this._license.asObservable();
  }

  get licenses(): License[] {
    return this._licenses.value;
  }

  /**
   * Request registration for hub with the given unique code
   * When it succeeds it returns the created ID of the hubset license order
   *
   * @memberof SpeakseeOrderService
   * @param registrationCode
   * @returns Observable of type HubOrderRegisterResponse
   */
  registerHubOrder(
    registrationCode: string
  ): Observable<HubOrderRegisterResponse> {
    return this.http.post<HubOrderRegisterResponse>(
      `${this.baseUrl}/huborder/register`,
      {
        /* eslint-disable @typescript-eslint/naming-convention */
        registration_code: registrationCode,
      } as HubOrderRegisterRequest
    );
  }

  /**
   * Get single hubSet order with the given hubSetLicenseOrderID
   *
   * @memberof SpeakseeOrderService
   * @param id
   * @returns Observable of type HubSetLicenseOrder
   */
  getHubSetLicenseOrderById(id: string): Observable<HubSetLicenseOrder> {
    return this.http
      .get<HubSetLicenseOrder[]>(`${this.baseUrl}/setlicenseorder/${id}`)
      .pipe(
        map((orders) => {
          if (orders.length !== 1) {
            throw new Error(`no order found with ID: ${id}`);
          }
          return orders[0];
        })
      );
  }
  /**
   * Retrieve all licenses for the current user
   *
   * @memberof SpeakseeOrderService
   * @returns Observable of type License[]
   */
  getLicenses(): Observable<License[]> {
    return forkJoin([
      this.getMicrophoneKitLicenses(),
      this.getAutoCaptionLicenses(),
    ]).pipe(
      take(1),
      map(([kit, ac]) => {
        this._licenses.next([...kit, ...ac]);
        return [...kit, ...ac];
      })
    );
  }

  /**
   * Retrieve all licenses for the current user
   *
   * @memberof SpeakseeOrderService
   * @returns Observable of type License[]
   */
  getMicrophoneKitLicenses(): Observable<License[]> {
    return this.http
      .get<License[]>(`${this.baseUrl}/setlicenseorder/product`)
      .pipe(
        map((licenses: License[]) => {
          if (!licenses) {
            return [];
          }
          licenses.forEach((license) => {
            license.type = 'microphonekit';
          });
          return licenses;
        })
      );
  }

  /**
   * Retrieve all licenses for the current user
   *
   * @memberof SpeakseeOrderService
   * @returns Observable of type License[]
   */
  getAutoCaptionLicenses(): Observable<License[]> {
    return this.http.get<License[]>(`${this.baseUrl}/acorder/products`).pipe(
      map((licenses: License[]) => {
        if (!licenses) {
          return [];
        }
        licenses.forEach((license) => {
          license.type = 'autocaption';
        });
        return licenses;
      })
    );
  }

  /**
   * Retrieve a single license by id or firestoredb id
   *
   * @memberof SpeakseeOrderService
   * @param id
   * @returns Observable of type License
   */
  getLicense(id: number | string): Observable<License> {
    // TODO: Make an api call to retrieve a license if it's not previously
    // cached
    return this._licenses.pipe(
      // take 1 is needed so we don't create a long lasting observable which gets fired multiple times
      // since getLicenses adds new licenses to this observable it will fire twice causing incorrect behavior
      take(1),
      switchMap((licenses: License[]) => {
        if (licenses.length === 0) {
          return this.getLicenses();
        }
        return of(licenses);
      }),
      map((licenses) => {
        const license = licenses.find(
          (l) => l.id === id || l.firestoredb === id
        );

        // FIXME: Pani_C! we don't have a way to retrieve one license.
        // This is just a workaround  #no_Oqa!
        if (!license) {
          throw new Error(`No license found with ID: ${id}`);
        }

        return license;
      })
    );
  }
}
