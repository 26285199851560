import { Directive, ElementRef } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Directive({
  selector: '[appSpeechViewportWidth]',
})
export class ViewportWidthDirective {
  constructor(el: ElementRef, settings: SettingsService) {
    settings.viewportWidth$.subscribe((x: number) => {
      x = el.nativeElement.clientWidth / x ? x / 2 : 1;
      el.nativeElement.style.paddingRight = `${x}%`;
    });
  }
}
