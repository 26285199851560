import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'liveTranslate',
})
export class LiveTranslatePipe implements PipeTransform {
  private translateSubject: BehaviorSubject<string>;
  private latestChange: string;
  constructor(private translateService: TranslateService) {}

  transform(value: any, source: { languageIn: string; languageOut: string }) {
    if (this.latestChange === '') {
      this.latestChange = value;
    }

    this.translateSubject = new BehaviorSubject(this.latestChange);
    this.translateService
      .translate(value, source.languageIn, source.languageOut)
      .then((x) => {
        this.translateSubject.next(x);
        this.latestChange = x;
      });

    return this.translateSubject.asObservable();
  }
}
