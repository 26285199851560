import { AccountService } from '@app/services/account.service';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { catchError, first, mergeMap, switchMap } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this.accountService.userValue;
    const isLoggedIn = user && user.token;
    const isApiUrl =
      request.url.startsWith(environment.speaksee.apiUrl) ||
      request.url.startsWith(environment.speaksee.authUrl);
    const isNotLogin = request.url.indexOf('/auth/login') === -1;
    const isNotRefresh = request.url.indexOf('/auth/refresh') === -1;

    if (isLoggedIn && isApiUrl && isNotLogin && isNotRefresh && user?.token) {
      const currentTime = new Date().getTime();

      // If refresh token expired logout and return error
      if (user.token.refreshExpireAt < currentTime) {
        return from(this.accountService.logout()).pipe(
          switchMap(() => throwError('Refresh token expired'))
        );
      }

      // If refresh token still valid retrieve new access tokens
      if (user.token.accessExpireAt < currentTime) {
        return this.accountService.refresh().pipe(
          first(),
          catchError((e) => throwError(e)),
          mergeMap((response) => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${response.access_token}`, // eslint-disable-line
              },
            });
            return next.handle(request);
          })
        );
      }

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user?.token?.accessToken}`, // eslint-disable-line
        },
      });
    }

    return next.handle(request);
  }
}
