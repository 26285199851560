import { ActivatedRoute, Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AccountService } from '@app/services/account.service';
import { License } from '@app/modules/license/license.types';
import { SpeakseeOrderService } from '@app/modules/license/license.service';

@Component({
  selector: 'app-hub-selector',
  templateUrl: './hub-selector.component.html',
  styleUrls: ['./hub-selector.component.scss'],
})
export class HubSelectorComponent implements OnInit {
  licenses$: Observable<License[]>;

  isQRAuthMode = sessionStorage.getItem('auth_mode') === 'qr';

  constructor(
    private orderService: SpeakseeOrderService,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const licenses: License[] = this.route.snapshot.data['licenses']; // Access the preloaded licenses from resolver
    const microphoneKitLicenses: License[] = licenses.filter(
      (license) => license.type === 'microphonekit'
    );

    // auto redirect
    if (licenses.length === 1) {
      await this.router.navigate(['/speech', licenses[0].firestoredb]);
    } else if (microphoneKitLicenses.length === 1) {
      await this.router.navigate([
        '/speech',
        microphoneKitLicenses[0].firestoredb,
      ]);
    }

    this.licenses$ = this.orderService.licenses$;
    // NOTE: We'll accept the wrong assumption that when a user is
    // authenticated via QR code, then he shouldn't be able to access this
    // view. This is only correct until the QR authentication method is well
    // implemented making use of the backend.
    if (this.isQRAuthMode) {
      await this.accountService.logout();
      await this.router.navigate(['/login']);
    }
  }

  async goToTranscription(license: License) {
    // Q: Why the firestoredb?
    await this.router.navigate(['/speech', license.firestoredb]);
  }
}
