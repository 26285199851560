<div mat-dialog-title>
  <div class="title-icon">
    <img src="assets/svg/language.svg" alt="" />
  </div>
  <div class="title-text">Language</div>
</div>
<mat-dialog-content class="mat-typography">
  <form class="form">
    <h3>Set language for</h3>
    <div class="select-mode">
      <mat-button-toggle-group [(value)]="updateMode" onchange="modeChanged()">
        <mat-button-toggle value="all">ALL</mat-button-toggle>
        <mat-button-toggle
          value="each"
          *ngIf="(this.kit | async).license.feature_flag_translation"
          >EACH</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>

    <div class="mic-settings">
      <div class="mic-settings__labels">
        <div></div>
        <div>Spoken</div>
        <div *ngIf="(this.kit | async)?.license?.feature_flag_translation">
          Translate
        </div>
      </div>

      <div class="mic-settings__container">
        <ng-container [ngSwitch]="updateMode">
          <ng-container *ngSwitchCase="'all'">
            <div class="mic-settings__mic">
              <div class="icon">
                <img src="assets/svg/kit-v2.svg" alt="" />
                <!-- <div class="icon__mic-name">kit</div> -->
              </div>
              <div class="language-in">
                <mat-form-field appearance="outline">
                  <mat-select
                    #languageInSelector
                    [disabled]="
                      !(this.kit | async)?.license.feature_flag_translation ||
                      this.isQRAuthMode
                    "
                    (valueChange)="languageInChanged(-1, $event)"
                    [value]="
                      this.languageService.getLangFromCode(
                        (this.kit | async).microphones[0]?.language_in ||
                          (currentLanguage$ | async)?.transcriptionLanguage
                            .languageCode
                      )
                    "
                  >
                    <mat-select-trigger>
                      <span
                        class="flag-icon mr-1"
                        [ngClass]="languageInSelector.value?.iconClass"
                      ></span>
                      {{
                        languageInSelector.value?.languageCode
                          .substring(0, 2)
                          .toUpperCase()
                      }}
                    </mat-select-trigger>
                    <mat-optgroup label="Verified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                          <mat-icon
                            *ngIf="lang.isVerified"
                            [ngStyle]="{
                              color: '#32aa64',
                              transform: 'scale(.7)'
                            }"
                            >verified</mat-icon
                          >
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>

                    <mat-optgroup label="Unverified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="!lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
              <i class="fa fa-arrow-right divider"></i>

              <div class="language-out">
                <mat-form-field appearance="outline">
                  <mat-select
                    #languageOutSelector
                    (valueChange)="languageOutChanged(-1, $event)"
                    [value]="
                      this.languageService.getLangFromCode(
                        (this.kit | async).microphones[0]?.language_out ||
                          (this.localLanguageOut$ | async)
                      )
                    "
                  >
                    <mat-select-trigger>
                      <span
                        class="flag-icon mr-1"
                        [ngClass]="languageOutSelector.value?.iconClass"
                      ></span>
                      {{
                        languageOutSelector.value?.languageCode
                          .substring(0, 2)
                          .toUpperCase()
                      }}
                    </mat-select-trigger>
                    <mat-optgroup label="Verified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                          <mat-icon
                            *ngIf="lang.isVerified"
                            [ngStyle]="{
                              color: '#32aa64',
                              transform: 'scale(.7)'
                            }"
                            >verified</mat-icon
                          >
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>
                    <mat-optgroup label="Unverified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="!lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'each'">
            <div
              class="mic-settings__mic"
              *ngFor="
                let mic of (this.kit | async).microphones;
                let index = index
              "
            >
              <div class="icon">
                <i class="fa fa-microphone" [style.color]="mic.color_hex"></i>
                <!-- <div class="icon__mic-name">{{ mic.color_hex }}</div> -->
              </div>
              <div class="language-in">
                <mat-form-field appearance="outline">
                  <mat-select
                    [disabled]="
                      !(this.kit | async)?.license.feature_flag_translation ||
                      this.isQRAuthMode
                    "
                    #languageInSelector
                    (valueChange)="languageInChanged(mic.mic_id, $event)"
                    [value]="
                      this.languageService.getLangFromCode(mic.language_in)
                    "
                  >
                    <mat-select-trigger>
                      <span
                        class="flag-icon mr-1"
                        [ngClass]="languageInSelector.value?.iconClass"
                      ></span>
                      {{
                        languageInSelector.value?.languageCode
                          .substring(0, 2)
                          .toUpperCase()
                      }}
                    </mat-select-trigger>
                    <mat-optgroup label="Verified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                          <mat-icon
                            *ngIf="lang.isVerified"
                            [ngStyle]="{
                              color: '#32aa64',
                              transform: 'scale(.7)'
                            }"
                            >verified</mat-icon
                          >
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>

                    <mat-optgroup label="Unverified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="!lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
              <i
                class="fa fa-arrow-right divider"
                *ngIf="(this.kit | async)?.license?.feature_flag_translation"
              ></i>

              <div
                class="language-out"
                *ngIf="(this.kit | async)?.license?.feature_flag_translation"
              >
                <mat-form-field appearance="outline">
                  <mat-select
                    #languageOutSelector
                    (valueChange)="languageOutChanged(mic.mic_id, $event)"
                    [value]="
                      this.languageService.getLangFromCode(mic.language_out)
                    "
                  >
                    <mat-select-trigger>
                      <span
                        class="flag-icon mr-1"
                        [ngClass]="languageOutSelector.value?.iconClass"
                      ></span>
                      {{
                        languageOutSelector.value?.languageCode
                          .substring(0, 2)
                          .toUpperCase()
                      }}
                    </mat-select-trigger>
                    <mat-optgroup label="Verified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                          <mat-icon
                            *ngIf="lang.isVerified"
                            [ngStyle]="{
                              color: '#32aa64',
                              transform: 'scale(.7)'
                            }"
                            >verified</mat-icon
                          >
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>
                    <mat-optgroup label="Unverified">
                      <ng-container *ngFor="let lang of languages">
                        <mat-option [value]="lang" *ngIf="!lang.isVerified">
                          <span
                            class="flag-icon mr-1"
                            [ngClass]="lang.iconClass"
                          ></span
                          >{{ lang.exonym }}
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
