<sps-login
  (successfulLogin)="onLogin()"
  (unverifiedEmail)="onUnverified($event)"
  [translations]="this.loginLabels | async"
  [showError]="true"
  (errorEvent)="onError($event)"
>
  <ng-container header>
    <img src="assets/logo.svg" alt="" />
    <span> {{ 'login.slogan' | translate }} </span>
  </ng-container>
  <ng-container footer>
    <div class="register">
      <span>
        {{ 'login.sign_up_text' | translate }}
      </span>
      <a [href]="registerURL">{{ 'login.sign_up' | translate }}</a>
    </div>

    <div class="trouble">
      <span>{{ 'login.trouble_signing_in' | translate }} </span>
      <a [href]="resetPasswordURL">{{
        'login.reset_password_button' | translate
      }}</a>
    </div>

    <div class="faq">
      <span>{{ 'login.faq' | translate }} </span>
      <a href="https://speak-see.com/apps/help-center">{{
        'login.faq_link' | translate
      }}</a>
    </div>
  </ng-container>
</sps-login>

<ng-template #emailDialog>
  <h2 matDialogTitle>
    {{ 'login.email_not_active_popup.header' | translate }}
  </h2>
  <mat-dialog-content>
    <p [innerHTML]="'login.email_not_active_popup.body' | translate"></p>
    <a [href]="requestEmail + '?email=' + this.email" target="_blank"
      >Request new verification mail</a
    >
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close>
      {{ 'login.email_not_active_popup.close' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
