import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-403',
  templateUrl: './403.component.html',
  styleUrls: ['./403.component.scss'],
})
export class Error403Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
