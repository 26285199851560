import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Language } from '../shared/models/app.model';

export interface LanguageSelection {
  transcriptionLanguage: Language;
  translationLanguage: Language;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public currentLanguage$: Observable<LanguageSelection>;
  public languages: Language[];

  private readonly defaultLang = 'en-US';
  private currentLanguageSubject: BehaviorSubject<LanguageSelection>;

  constructor() {
    // languageCode: <iso 639-1>-<iso 3166-1 alpha-2>
    this.languages = [
      /* eslint-disable max-len */
      {
        languageCode: 'da-DK',
        isVerified: true,
        exonym: 'Danish',
        language: 'Danish',
        iconClass: 'fi fi-dk',
      },
      {
        languageCode: 'de-CH',
        isVerified: true,
        exonym: 'German (Switzerland)',
        language: 'Deutsche (Schweiz)',
        iconClass: 'fi fi-ch',
      },
      {
        languageCode: 'de-DE',
        isVerified: true,
        exonym: 'German',
        language: 'Deutsch',
        iconClass: 'fi fi-de',
      },
      {
        languageCode: 'en-GB',
        isVerified: true,
        exonym: 'English (United Kingdom)',
        language: 'English (UK)',
        iconClass: 'fi fi-gb',
      },
      {
        languageCode: 'en-US',
        isVerified: true,
        exonym: 'English (United States)',
        language: 'English (US)',
        iconClass: 'fi fi-us',
      },
      {
        languageCode: 'es-ES',
        isVerified: true,
        exonym: 'Spanish',
        language: 'Español',
        iconClass: 'fi fi-es',
      },
      {
        languageCode: 'fr-FR',
        isVerified: true,
        exonym: 'French',
        language: 'Français',
        iconClass: 'fi fi-fr',
      },
      {
        languageCode: 'it-IT',
        isVerified: true,
        exonym: 'Italian',
        language: 'Italiano',
        iconClass: 'fi fi-it',
      },
      {
        languageCode: 'nl-NL',
        isVerified: true,
        exonym: 'Dutch',
        language: 'Nederlands',
        iconClass: 'fi fi-nl',
      },
      {
        languageCode: 'pt-PT',
        isVerified: true,
        exonym: 'Portuguese',
        language: 'Português',
        iconClass: 'fi fi-pt',
      },
      {
        languageCode: 'af-ZA',
        isVerified: false,
        exonym: 'Afrikaans',
        language: 'Afrikaans',
        iconClass: 'fi fi-za',
      },
      {
        languageCode: 'ar-AE',
        isVerified: false,
        exonym: 'Arabic (UAE)',
        language: 'العربية (AE)',
        iconClass: 'fi fi-ae',
      },
      {
        languageCode: 'ar-MA',
        isVerified: false,
        exonym: 'Arabic (Morocco)',
        language: 'العربية (MA)',
        iconClass: 'fi fi-ma',
      },
      {
        languageCode: 'ar-SY',
        isVerified: false,
        exonym: 'Arabic (Syria)',
        language: 'اللهجة العربيّة السورىّة (SY)',
        iconClass: 'fi fi-sy',
      },
      {
        languageCode: 'ca-ES',
        isVerified: false,
        exonym: 'Catalan',
        language: 'Catalán',
        iconClass: 'fi fi-es-ct',
      },
      {
        languageCode: 'cs-CZ',
        isVerified: false,
        exonym: 'Czech (Czech Republic)',
        language: 'Čeština',
        iconClass: 'fi fi-cz',
      },
      {
        languageCode: 'el-GR',
        isVerified: false,
        exonym: 'Greek',
        language: 'ελληνικά',
        iconClass: 'fi fi-gr',
      },
      {
        languageCode: 'en-AU',
        isVerified: false,
        exonym: 'English (Australia)',
        language: 'English (AU)',
        iconClass: 'fi fi-au',
      },
      {
        languageCode: 'en-CA',
        isVerified: false,
        exonym: 'English (Canada)',
        language: 'English (CA)',
        iconClass: 'fi fi-ca',
      },
      {
        languageCode: 'en-NZ',
        isVerified: false,
        exonym: 'English (New Zealand)',
        language: 'English (NZ)',
        iconClass: 'fi fi-nz',
      },
      {
        languageCode: 'en-PH',
        isVerified: false,
        exonym: 'English (Philippines)',
        language: 'English (PH)',
        iconClass: 'fi fi-ph',
      },
      {
        languageCode: 'en-SG',
        isVerified: false,
        exonym: 'English (Singapore)',
        language: 'English (SG)',
        iconClass: 'fi fi-sg',
      },
      {
        languageCode: 'en-NG',
        isVerified: false,
        exonym: 'English (Nigeria)',
        language: 'English (NG)',
        iconClass: 'fi fi-ng',
      },
      {
        languageCode: 'en-ZA',
        isVerified: false,
        exonym: 'English (South African)',
        language: 'English (ZA)',
        iconClass: 'fi fi-za',
      },
      {
        languageCode: 'es-AR',
        isVerified: false,
        exonym: 'Spanish (Argentina)',
        language: 'Español (AR)',
        iconClass: 'fi fi-ar',
      },
      {
        languageCode: 'es-MX',
        isVerified: false,
        exonym: 'Spanish (Mexico)',
        language: 'Español (MX)',
        iconClass: 'fi fi-mx',
      },
      {
        languageCode: 'es-US',
        isVerified: false,
        exonym: 'Spanish (United States)',
        language: 'Español (US)',
        iconClass: 'fi fi-us',
      },
      {
        languageCode: 'fa-IR',
        isVerified: false,
        exonym: 'Persian',
        language: 'فارسی',
        iconClass: 'fi fi-ir',
      },
      {
        languageCode: 'fi-FI',
        isVerified: false,
        exonym: 'Finnish',
        language: 'Suomi',
        iconClass: 'fi fi-fi',
      },
      {
        languageCode: 'fil-PH',
        isVerified: false,
        exonym: 'Filipino',
        language: 'Filipino',
        iconClass: 'fi fi-ph',
      },
      {
        languageCode: 'fr-CA',
        isVerified: false,
        exonym: 'French (Canada)',
        language: 'Français (CA)',
        iconClass: 'fi fi-ca',
      },
      {
        languageCode: 'fr-CH',
        isVerified: false,
        exonym: 'French (Switzerland)',
        language: 'Français (CH)',
        iconClass: 'fi fi-ch',
      },
      {
        languageCode: 'gl-ES',
        isVerified: false,
        exonym: 'Galician',
        language: 'Galego',
        iconClass: 'fi fi-es-ga',
      },
      {
        languageCode: 'hi-IN',
        isVerified: false,
        exonym: 'Hindi',
        language: 'हिन्दी',
        iconClass: 'fi fi-in',
      },
      {
        languageCode: 'hr-HR',
        isVerified: false,
        exonym: 'Croatian',
        language: 'Hrvatski',
        iconClass: 'fi fi-hr',
      },
      {
        languageCode: 'hu-HU',
        isVerified: false,
        exonym: 'Hungarian',
        language: 'magyar',
        iconClass: 'fi fi-hu',
      },
      {
        languageCode: 'hy-AM',
        isVerified: false,
        exonym: 'Armenian',
        language: 'Հայերեն',
        iconClass: 'fi fi-am',
      },
      {
        languageCode: 'it-CH',
        isVerified: false,
        exonym: 'Italian (Switzerland)',
        language: 'Italiano (CH)',
        iconClass: 'fi fi-ch',
      },
      {
        languageCode: 'iw-IL',
        isVerified: false,
        exonym: 'Hebrew',
        language: 'עברית',
        iconClass: 'fi fi-il',
      },
      {
        languageCode: 'ja-JP',
        isVerified: false,
        exonym: 'Japanese',
        language: '日本语',
        iconClass: 'fi fi-jp',
      },
      {
        languageCode: 'ko-KR',
        isVerified: false,
        exonym: 'Korean',
        language: '한국어',
        iconClass: 'fi fi-kr',
      },
      {
        languageCode: 'lt-LT',
        isVerified: false,
        exonym: 'Lithuanian',
        language: 'Lietuvių',
        iconClass: 'fi fi-lt',
      },
      {
        languageCode: 'ms-MY',
        isVerified: false,
        exonym: 'Malay',
        language: 'Bahasa Melayu',
        iconClass: 'fi fi-my',
      },
      {
        languageCode: 'nb-NO',
        isVerified: false,
        exonym: 'Norwegian Bokmål',
        language: 'Norsk',
        iconClass: 'fi fi-no',
      },
      {
        languageCode: 'nl-BE',
        isVerified: false,
        exonym: 'Flemish',
        language: 'Nederlands (België)',
        iconClass: 'fi fi-be',
      },
      {
        languageCode: 'pl-PL',
        isVerified: false,
        exonym: 'Polish',
        language: 'Polski',
        iconClass: 'fi fi-pl',
      },
      {
        languageCode: 'pt-BR',
        isVerified: false,
        exonym: 'Portuguese (Brazil)',
        language: 'Português (BR)',
        iconClass: 'fi fi-br',
      },
      {
        languageCode: 'ro-RO',
        isVerified: false,
        exonym: 'Romanian (Romania)',
        language: 'român',
        iconClass: 'fi fi-ro',
      },
      {
        languageCode: 'ru-RU',
        isVerified: false,
        exonym: 'Russian',
        language: 'Русский язык',
        iconClass: 'fi fi-ru',
      },
      {
        languageCode: 'sk-SK',
        isVerified: false,
        exonym: 'Slovak',
        language: 'Slovenčina',
        iconClass: 'fi fi-sk',
      },
      {
        languageCode: 'sl-SL',
        isVerified: false,
        exonym: 'Slovenian',
        language: 'Slovenščina',
        iconClass: 'fi fi-sl',
      },
      {
        languageCode: 'sr-RS',
        isVerified: false,
        exonym: 'Serbian',
        language: 'српски',
        iconClass: 'fi fi-rs',
      },
      {
        languageCode: 'sv-SE',
        isVerified: false,
        exonym: 'Swedish',
        language: 'Svenska',
        iconClass: 'fi fi-se',
      },
      {
        languageCode: 'sw-KE',
        isVerified: false,
        exonym: 'Swahili (Kenya)',
        language: 'Kiswahili (KE)',
        iconClass: 'fi fi-ke',
      },
      {
        languageCode: 'sw-TZ',
        isVerified: false,
        exonym: 'Swahili (Tanzania)',
        language: 'Kiswahili (TZ)',
        iconClass: 'fi fi-tz',
      },
      {
        languageCode: 'ta-SG',
        isVerified: false,
        exonym: 'Tamil',
        language: 'தமிழ்',
        iconClass: 'fi fi-sg',
      },
      {
        languageCode: 'tr-TR',
        isVerified: false,
        exonym: 'Turkish',
        language: 'Türkçe',
        iconClass: 'fi fi-tr',
      },
      {
        languageCode: 'uk-UA',
        isVerified: true,
        exonym: 'Ukrainian',
        language: 'Українська',
        iconClass: 'fi fi-ua',
      },
      {
        languageCode: 'vi-VN',
        isVerified: false,
        exonym: 'Vietnamese',
        language: 'tiếng việt (㗂越)',
        iconClass: 'fi fi-vn',
      },
      {
        languageCode: 'zh-HK',
        isVerified: false,
        exonym: 'Cantonese (Hong Kong)',
        language: '粵語 (YUE)',
        iconClass: 'fi fi-cn',
      },
      {
        languageCode: 'zh-TW',
        isVerified: false,
        exonym: 'Mandarin (Taiwan)',
        language: '國語 (TW)',
        iconClass: 'fi fi-cn',
      },
      {
        languageCode: 'zh',
        isVerified: false,
        exonym: 'Mandarin',
        language: '中文',
        iconClass: 'fi fi-cn',
      },
      {
        languageCode: 'zu-ZA',
        isVerified: false,
        exonym: 'Zulu',
        language: 'isiZulu',
        iconClass: 'fi fi-za',
      },
      {
        languageCode: 'cy-GB',
        isVerified: false,
        exonym: 'Welsh',
        language: 'Welsh (UK)',
        iconClass: 'fi fi-gb-wls',
      },
      /* eslint-disable max-len */
    ];
    const langCode =
      localStorage.getItem('currentLanguage') || this.defaultLang;
    this.currentLanguageSubject = new BehaviorSubject({
      transcriptionLanguage: this.getLangFromCode(langCode),
      translationLanguage: this.getLangFromCode(langCode),
    });

    this.currentLanguage$ = this.currentLanguageSubject.asObservable();
    this.currentLanguage$.subscribe((x) => {
      localStorage.setItem(
        'currentLanguage',
        x.translationLanguage.languageCode
      );
    });
  }

  public setTranscriptionLanguage(languageCode: string): void {
    const lang = this.getLangFromCode(languageCode);
    if (!lang) {
      console.error(`Language with code ${languageCode} does not exist`);
      return;
    }
    this.currentLanguageSubject.next({
      ...this.currentLanguageSubject.value,
      ...({
        transcriptionLanguage: lang,
      } as LanguageSelection),
    });
  }

  public setTranslationLanguage(languageCode: string): void {
    const lang = this.getLangFromCode(languageCode);
    if (!lang) {
      console.error(`Language with code ${languageCode} does not exist`);
      return;
    }
    this.currentLanguageSubject.next({
      ...this.currentLanguageSubject.value,
      ...({
        translationLanguage: lang,
      } as LanguageSelection),
    });
  }

  public getLangFromCode(code: string): Language {
    return this.languages.find((lang) => lang.languageCode === code);
  }
}
