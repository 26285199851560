import { Injectable } from '@angular/core';
import NoSleep from 'nosleep.js';

@Injectable({
  providedIn: 'root',
})
export class NoSleepService {
  private noSleepInstance: NoSleep;
  constructor() {
    this.noSleepInstance = new NoSleep();
  }

  /**
   * Turns on anti sleep protection
   */
  public enable() {
    this.noSleepInstance.enable();
  }

  /**
   * Turns off anti sleep protection
   */
  public disable() {
    this.noSleepInstance.disable();
  }
}
