<header class="header footer" #header>
  <!-- Fake button used for old IAB code in the app, will freeze without currently -->
  <div class="button" style="position: absolute"></div>
  <div class="logout" routerLink="microphone-kits" *ngIf="!enableLogout">
    <i class="fa fa-angle-left"></i>
  </div>

  <div class="logout" (click)="logout()" *ngIf="enableLogout">
    <ng-container [ngSwitch]="inAppBrowser">
      <i class="fa fa-angle-left" *ngSwitchCase="true"></i>
      <button mat-button (click)="logout()" *ngSwitchCase="false">
        Logout
      </button>
    </ng-container>
  </div>

  <div class="logo" (click)="clickLogo()">
    <img src="assets/logo.svg" />
  </div>

  <div class="options" [style.visibility]="!noButtons ? 'visible' : 'hidden'">
    <div class="language" (click)="openLanguageSettings()">
      <img src="assets/svg/language.svg" alt="" />
    </div>
    <div class="menu" mat-button [matMenuTriggerFor]="menu">
      <i class="fa fa-bars"></i>
    </div>
    <!--
      TODO: Improve accessibility
      https://material.angular.io/components/menu/overview#accessibility
    -->
    <mat-menu #menu="matMenu" class="menu">
      <button
        mat-menu-item
        (click)="downloadTranscript()"
        aria-label="save"
        *ngIf="showDownloadButton"
      >
        <mat-icon>save</mat-icon>
        <span>Save (PC only)</span>
      </button>
      <button
        mat-menu-item
        (click)="openLanguageSettings()"
        aria-label="languages"
      >
        <mat-icon>language</mat-icon>
        <span>Languages</span>
      </button>
      <!-- NOTE: Not in use.
        <button mat-menu-item (click)="openMenuPersonalize()">
        <img src="assets/svg/personalise.svg" alt="personalize icon" />
        Personalize
      </button> -->
      <button
        mat-menu-item
        (click)="openMenuAccessibilitySettings()"
        aria-label="accessibility"
      >
        <mat-icon>settings_accessibility</mat-icon>
        <span>Accessibility</span>
      </button>
      <button
        mat-menu-item
        (click)="openMenuDisplaySettings()"
        aria-label="customize"
        *ngIf="showDisplayButton"
      >
        <mat-icon>edit</mat-icon>
        <span>Display</span>
      </button>
      <button
        mat-menu-item
        (click)="openDialogShare()"
        aria-label="share"
        *ngIf="showShareButton"
      >
        <mat-icon>share</mat-icon>
        <span>Share</span>
      </button>
    </mat-menu>
  </div>
</header>
