import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-scroll-back-down-button',
  templateUrl: './scroll-back-down-button.component.html',
  styleUrls: ['./scroll-back-down-button.component.scss'],
})
export class ScrollBackDownButtonComponent implements OnInit {
  @Output() childScrollToBottom = new EventEmitter();
  @Input() childIsAtBottom: () => boolean;
  @Input() messageLength: number;
  // The current message we have read until
  curMessage = 0;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';

  newMessages = new Observable((observer) => {
    setInterval(() => {
      observer.next(this.messageLength - this.curMessage);
    }, 500);
  });

  constructor(private _snackBar: MatSnackBar) {}

  @HostListener('window:wheel', ['$event'])
  @HostListener('window:touchend', ['$event'])
  mouse($event) {
    switch ($event.type) {
      case 'wheel':
      case 'touchend':
        if (!this.childIsAtBottom()) {
          if (this._snackBar._openedSnackBarRef == null) {
            this.openSnackBar();
          }
        } else {
          this.curMessage = this.messageLength;
          this._snackBar.dismiss();
        }
        break;
    }
  }

  ngOnInit(): void {}

  openSnackBar() {
    const snackBarRef = this._snackBar.openFromComponent(
      CustomSnackbarComponent,
      {
        panelClass: 'scroll-down-snackbar',
        horizontalPosition: this.horizontalPosition,
        data: this.newMessages,
      }
    );

    snackBarRef.onAction().subscribe(() => {
      this.childScrollToBottom.emit();
    });
  }
}

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.html',
  styleUrls: ['./custom-snackbar.scss'],
})
export class CustomSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<ScrollBackDownButtonComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: Observable<unknown>
  ) {}
}
