import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';

import { Component, ElementRef, Inject, ViewChild } from '@angular/core';

import { QRCodeElementType, QRCodeErrorCorrectionLevel } from 'angularx-qrcode';

import { AccountService } from '@app/services/account.service';
import { Moment } from 'moment';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareDialogComponent {
  @ViewChild('qrCanvas', { static: false }) qrCanvas: ElementRef;

  showPassword = false;
  showQRCode = false;
  loading = false;

  form: FormGroup = new FormGroup({
    email: new FormControl('', Validators.email),
    password: new FormControl(''),
    expirationDate: new FormControl(undefined),
  });

  credentialError: string;

  now: Date = new Date();
  tomorrow: Date = new Date(
    this.now.getFullYear(),
    this.now.getMonth(),
    this.now.getDate() + 1,
    24,
    0,
    0,
    0
  );

  allowEmptyString = true;
  alt = 'Authentication QR code';
  ariaLabel: 'QR code to share current conversation';
  elementType = 'canvas' as QRCodeElementType;
  errorCorrectionLevel = 'M' as QRCodeErrorCorrectionLevel;
  imageSrc = './assets/speaksee_logo_500.svg';
  imageHeight = 200;
  imageWidth = 200;
  margin = 4;
  qrdata = '';
  scale = 1;
  title = 'Share current conversation';
  width = 1000;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private clipboard: Clipboard,
    private accountService: AccountService
  ) {}

  // --------------------------------------------------------------------------
  // @ Public methods
  // --------------------------------------------------------------------------

  /**
   * Generates a QR code based on the current value of `qrdata` and toggles
   * the visibility of the QR code.
   */
  async generateQRCode({ email, password, expirationDate }) {
    try {
      this.form.disable();
      this.loading = true;
      await this.accountService.login(email, password).toPromise();
      this.qrdata = this._qrdata(email, password, expirationDate);
      this.showQRCode = !this.showQRCode;
    } catch (e) {
      this.credentialError = e?.error?.message ?? e.message;
    } finally {
      this.form.enable();
      this.loading = false;
    }
  }

  /**
   * Downloads a QR code image from a given qrcanvas element.
   *
   * @param qrcanvas - The qrcanvas element to download the image from.
   */
  download(qrcanvas) {
    if (!qrcanvas) {
      return;
    }
    const canvasElement =
      qrcanvas.qrcElement.nativeElement.querySelector('canvas');

    const blob = new Blob(
      [this._convertBase64ToBlob(canvasElement.toDataURL('image/png'))],
      {
        type: 'image/png',
      }
    );
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'authentication-qrcode';
    link.click();
  }

  /**
   * Copies the QR code href to the clipboard.
   *
   * @returns A Promise that resolves when the copy operation is complete.
   */
  copyQRCodeHref() {
    return this.clipboard.copy(this.qrdata);
  }

  // --------------------------------------------------------------------------
  // @ Private methods
  // --------------------------------------------------------------------------

  /**
   * Converts a base64 encoded image to a Blob object.
   *
   * @param base64Image - The base64 encoded image string.
   * @returns A Blob object representing the image.
   */
  private _convertBase64ToBlob(base64Image: string) {
    const groups = /^data:(?<mimetype>image\/png);base64,(?<data>.*)$/.exec(
      base64Image
    )['groups'];

    const decodedData = window.atob(groups.data);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: groups.mimetype });
  }

  /**
   * Returns a string containing the URL with a sign-in code for sharing the
   * current page.
   *
   * @returns The URL with a sign-in code.
   */
  private _qrdata(
    email: string,
    password: string,
    expirationDate?: Moment
  ): string {
    const params = [email.trim(), password.trim()];

    // Extend params with expiration date if it is set
    if (expirationDate) {
      params.push(expirationDate.toDate().getTime().toString());
    }

    return `${
      this.document.location.href
    }?signInCode=${this.accountService.obfuscate(params)}`;
  }
}
