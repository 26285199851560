<div data-hj-suppress class="speech-bubble mask-me" appDynamicTheme *ngIf="microphone">
  <!-- NOTE: div below is for compact timestamps -->
  <div
    class="speech-bubble__info"
    appSpeechBubbleText
    *ngIf="
      (showMessageMetadata$ | async) === false &&
      checkRender(message.updated_at, prevTimestamp) &&
      (currentTheme$ | async) === themes.color
    "
  >
    {{ formatTime(message.updated_at) }}
  </div>
  <p
    class="speech-bubble__info"
    *ngIf="
      (currentTheme$ | async) === themes.color &&
      (showMessageMetadata$ | async) === true
    "
  >
    {{
      isNaN(+microphone?.name)
        ? microphone?.name
        : 'Microphone ' + message.micColor.name
    }}- [
    {{
      (micSettings$ | async)?.language_in.substr(0, 2).toUpperCase() ||
        message.language.substr(0, 2).toUpperCase()
    }}
    <ng-container
      *ngIf="
        (!(micSettings$ | async)?.language_in ||
          (micSettings$ | async)?.language_in !==
            (micSettings$ | async)?.language_out) &&
        (showMessageMetadata$ | async) === true
      "
    >
      <i class="fa fa-arrow-right"></i>
      {{
        (micSettings$ | async)?.language_out.substr(0, 2).toUpperCase() ||
          (this.localLanguageOut$ | async).substr(0, 2).toUpperCase()
      }}
    </ng-container>
    ]
    <span *ngIf="message.timestamp">
      at {{ formatTime(message.updated_at) }}</span
    >
  </p>

  <div class="speech-bubble__content">
    <span
      *ngIf="(currentTheme$ | async) !== themes.color"
      class="speech-bubble__content__indicator"
      [ngStyle]="{ 'background-color': this.microphone.color }"
    >
      {{ microphone?.name[0].toUpperCase() }}
    </span>

    <span
      class="speech-bubble__content__container"
      [ngClass]="{ 'clean-theme': (currentTheme$ | async) === themes.clean }"
      [ngStyle]="{ color: this.microphone.color }"
      [style.background-color]="
        (currentTheme$ | async) === themes.color ? this.microphone.color : ''
      "
    >
      <span
        class="speech-bubble__content__container__timestamp"
        *ngIf="
          (currentTheme$ | async) !== themes.color &&
          (showMessageMetadata$ | async) === true
        "
        >{{
          isNaN(+microphone?.name)
            ? microphone?.name
            : 'Microphone ' + message.micColor.name
        }}
        - [
        {{
          (micSettings$ | async)?.language_in.substr(0, 2).toUpperCase() ||
            message.language.substr(0, 2).toUpperCase()
        }}
        <ng-container
          *ngIf="
            !(micSettings$ | async)?.language_in ||
            (micSettings$ | async)?.language_in !==
              (micSettings$ | async)?.language_out
          "
        >
          <i class="fa fa-arrow-right"></i>
          {{
            (micSettings$ | async)?.language_out.substr(0, 2).toUpperCase() ||
              (this.localLanguageOut$ | async).substr(0, 2).toUpperCase()
          }}
        </ng-container>
        ] - {{ formatTime(message.updated_at) }}</span
      >
      <!-- NOTE: Glow theme change  -->
      <span
        class="speech-bubble__content__container__timestamp"
        *ngIf="
          (showMessageMetadata$ | async) === false &&
          checkRender(message.updated_at, prevTimestamp) &&
          (currentTheme$ | async) !== themes.color
        "
        >{{ formatTime(message.updated_at) }}</span
      >
      <span class="speech-bubble__content__container__text" appSpeechBubbleText>
        {{
          message.message
            | liveTranslate
              : {
                  languageIn:
                    (micSettings$ | async)?.language_in || message.language,
                  languageOut:
                    (micSettings$ | async)?.language_out ||
                    (this.localLanguageOut$ | async)
                }
            | async
        }}</span
      >
    </span>
    <span
      *ngIf="(currentTheme$ | async) === themes.color"
      [style.border-right-color]="message.micColor.hex"
      class="speech-bubble__content__pointer"
    ></span>
  </div>
</div>
