import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { MicrophoneSettings } from '@speaksee/speaksee-angular-core';

import { Message } from '../../../shared/models/app.model';
import { Microphone } from 'src/app/shared/models/microphone.model';
import { SpeechService } from 'src/app/services/speech.service';

import {
  IRemoteSettings,
  SettingsService,
  Themes,
} from 'src/app/services/settings.service';

@Component({
  selector: 'app-speech-bubble',
  templateUrl: './speech-bubble.component.html',
  styleUrls: ['./speech-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeechBubbleComponent implements OnChanges {
  @Input() message: Message;
  @Input() prevTimestamp: Date;
  public themes = Themes;
  public currentTheme$: Observable<Themes>;
  public microphone: Microphone = null;
  public microphones: Microphone[];
  public isNaN = isNaN;
  public displayTS = false;
  public localLanguageOut$: Observable<string>;
  public micSettings$: Observable<MicrophoneSettings>;
  public showMessageMetadata$: Observable<boolean>;

  constructor(
    private cd: ChangeDetectorRef,
    private settingsService: SettingsService,
    private speechService: SpeechService
  ) {
    this.localLanguageOut$ = this.settingsService.localLanguageOut$;
    this.currentTheme$ = this.settingsService.theme$;
    this.showMessageMetadata$ = this.settingsService.showMessageMetadata$;
  }

  ngOnChanges() {
    this.speechService.activeMics$
      .pipe(take(1))
      .subscribe((mics: Microphone[]) => {
        const mic = mics.find((_mic) => _mic.id === this.message.micId);
        this.microphone = mic;
        this.micSettings$ = this.settingsService
          .getRemoteSetSettings(-1, false)
          .pipe(
            map((settings: IRemoteSettings) =>
              settings.microphones.find(
                (micSettings: MicrophoneSettings) =>
                  micSettings.mic_id === mic.id
              )
            )
          );
      });
  }

  checkRender(timestamp1: any, timestamp2: Date): boolean {
    const date1 = new Date(timestamp1);
    const hours1 = date1.getHours();
    const minutes1 = date1.getMinutes();
    const date2 = new Date(timestamp2);
    const hours2 = date2.getHours();
    const minutes2 = date2.getMinutes();

    if (timestamp2 === undefined) {
      return true;
    } else if (hours1 === hours2 && minutes1 === minutes2) {
      return false;
    } else {
      return true;
    }
  }

  formatTime(timestamp: any) {
    if (timestamp) {
      const date = new Date(timestamp);
      const hours = date.getHours();
      const minutes = date.getMinutes();

      let stringMinutes = String(minutes);

      if (minutes < 10) {
        stringMinutes = `0${minutes}`;
      }

      return `${hours}:${stringMinutes}`;
    }
  }

  getMessageAfterStyle(color) {
    return 'transparent #' + color + ' transparent transparent';
  }
}
