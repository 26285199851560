<!-- <h2 mat-dialog-title>
  <mat-icon style="vertical-align: middle; margin-right: 16px">share</mat-icon>
  <span>Share conversation</span>
</h2> -->

<div mat-dialog-title>
  <div class="title-icon">
    <mat-icon>share</mat-icon>
  </div>
  <div class="title-text">Share conversation</div>
</div>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <div *ngIf="!showQRCode" style="text-align: center">
      <mat-form-field>
        <mat-label>Enter your email</mat-label>
        <input matInput placeholder="Email" formControlName="email" required />
        <mat-error>invalid email format</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Enter your password</mat-label>
        <input
          matInput
          placeholder="Password"
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          required
        />

        <button
          mat-icon-button
          matSuffix
          (click)="showPassword = !showPassword"
          [attr.aria-label]="'Show password'"
          [attr.aria-pressed]="showPassword"
        >
          <mat-icon>{{
            showPassword ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field>
        <mat-label>QR code expiration date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [min]="tomorrow"
          formControlName="expirationDate"
        />
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-error
        *ngIf="this.credentialError"
        [innerHTML]="this.credentialError"
      >
      </mat-error>
    </div>
  </form>

  <div class="qrcode_wrapper">
    <qrcode
      #qrCanvas
      [elementType]="elementType"
      [errorCorrectionLevel]="errorCorrectionLevel"
      [qrdata]="qrdata"
      [scale]="scale"
      [width]="width"
      [allowEmptyString]="true"
      [cssClass]="'center'"
      [margin]="margin"
      [alt]="alt"
      [ariaLabel]="ariaLabel"
      [title]="title"
      [imageSrc]="imageSrc"
      [imageHeight]="imageHeight"
      [imageWidth]="imageWidth"
      *ngIf="showQRCode"
    ></qrcode>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    [disabled]="form.invalid"
    (click)="generateQRCode(this.form.value)"
    *ngIf="!showQRCode"
    cdkFocusInitial
  >
    Generate QR Code

    <mat-icon *ngIf="loading"
      ><mat-progress-spinner
        diameter="20"
        mode="indeterminate"
        strokeWidth="11%"
      >
      </mat-progress-spinner
    ></mat-icon>
  </button>
  <button
    mat-button
    color="secondary"
    (click)="copyQRCodeHref()"
    *ngIf="showQRCode"
  >
    Copy Link
  </button>
  <button
    mat-button
    color="secondary"
    (click)="download(qrCanvas)"
    *ngIf="showQRCode"
  >
    Download
  </button>
  <button mat-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
