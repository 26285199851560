import { Injectable } from '@angular/core';

import { BehaviorSubject, merge, Observable, timer } from 'rxjs';
import { concatMap, map, switchMap, takeUntil } from 'rxjs/operators';

import {
  MicrophoneSettings,
  SetLicenseSettings,
} from '@speaksee/speaksee-angular-core';
import {
  SettingsApiService,
  UpdateMicrophoneSettingsRequest,
} from './settings-api.service';

export enum Themes { // eslint-disable-line
  color = 'color-theme',
  glow = 'glow-theme',
  clean = 'clean-theme',
}

export interface IRemoteSettings {
  license: SetLicenseSettings;
  microphones: MicrophoneSettings[];
}

export interface ILocalSettings {
  fontSize: number;
  selectedTheme: Themes;
  languageOut: string;
  showMetadata: boolean;
  segmentationSilenceTimeout: number;
  splitOnFinal: boolean;
  onlyShowChangesOnFinal: boolean;
  viewportWidth: number;
}

export interface ISettings {
  kitSettings: IRemoteSettings;
  localSettings: ILocalSettings;
}

const defaultSettings: ISettings = {
  localSettings: {
    fontSize: 0,
    selectedTheme: Themes.color,
    languageOut: 'en-US',
    showMetadata: false,
    segmentationSilenceTimeout: 2500,
    splitOnFinal: true,
    onlyShowChangesOnFinal: false,
    viewportWidth: 0,
  },
  kitSettings: {
    license: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      custom_name: '',
      debug: false,
    } as any,
    microphones: [],
  },
};

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  isQRAuthMode = sessionStorage.getItem('auth_mode') === 'qr';

  public localLanguageOut$: Observable<string>;
  public showMessageMetadata$: Observable<boolean>;
  public localSettings$: Observable<ILocalSettings>;
  public kit$: Observable<IRemoteSettings>;
  // accessiblity
  public fontSize$: Observable<number>;
  public theme$: Observable<Themes>;
  public viewportWidth$: Observable<number>;

  private settings: ISettings;
  private showMessageMetadataSubject: BehaviorSubject<boolean>;
  private localLanguageOutSubject: BehaviorSubject<string>;
  private kitSubject: BehaviorSubject<IRemoteSettings>;
  private localSettingsSubject: BehaviorSubject<ILocalSettings>;
  // accessiblity
  private fontSizeSubject: BehaviorSubject<number>;
  private themeSubject: BehaviorSubject<Themes>;
  private viewportWidthSubject: BehaviorSubject<number>;

  private isPolling = undefined;

  constructor(private speakseeSettingsService: SettingsApiService) {
    this.settings = Object.assign(defaultSettings);

    this.settings.localSettings = Object.assign(
      this.settings.localSettings,
      JSON.parse(localStorage.getItem('localSettings'))
    );
    this.showMessageMetadataSubject = new BehaviorSubject(
      this.settings.localSettings.showMetadata
    );
    this.showMessageMetadata$ = this.showMessageMetadataSubject.asObservable();

    this.localLanguageOutSubject = new BehaviorSubject(
      this.settings.localSettings.languageOut
    );
    this.localLanguageOut$ = this.localLanguageOutSubject.asObservable();

    this.localSettingsSubject = new BehaviorSubject(
      this.settings.localSettings
    );
    this.localSettingsSubject.subscribe((x) => {
      this.settings.localSettings = x;
    });
    this.localSettings$ = this.localSettingsSubject.asObservable();

    this.kitSubject = new BehaviorSubject(this.settings.kitSettings);
    this.kit$ = this.kitSubject.asObservable();

    // accessiblity
    this.fontSizeSubject = new BehaviorSubject(
      this.settings.localSettings.fontSize
    );
    this.fontSize$ = this.fontSizeSubject.asObservable();
    this.fontSizeSubject.subscribe((x: number) => {
      this.settings.localSettings.fontSize = x;
    });

    this.themeSubject = new BehaviorSubject(
      this.settings.localSettings.selectedTheme
    );
    this.theme$ = this.themeSubject.asObservable();
    this.theme$.subscribe((x: any) => {
      this.settings.localSettings.selectedTheme = x;
    });

    this.viewportWidthSubject = new BehaviorSubject(
      this.settings.localSettings.viewportWidth
    );
    this.viewportWidth$ = this.viewportWidthSubject.asObservable();
    this.viewportWidthSubject.subscribe((x: number) => {
      this.settings.localSettings.viewportWidth = x;
    });

    merge(
      this.theme$,
      this.fontSize$,
      this.localLanguageOut$,
      this.showMessageMetadata$,
      this.viewportWidth$,
      this.localSettingsSubject
    ).subscribe(() => {
      this.saveLocalSettings();
    });
  }

  get localSettings() {
    return this.localSettingsSubject.value;
  }

  updateLocalSettings(settings: ILocalSettings) {
    this.localSettingsSubject.next(settings);
  }

  changeFontSize(size: number): void {
    this.fontSizeSubject.next(size);
  }

  toggleDisplayMetadata(): void {
    this.settings.localSettings.showMetadata =
      !this.settings.localSettings.showMetadata;
    this.showMessageMetadataSubject.next(
      this.settings.localSettings.showMetadata
    );
  }

  changeTheme(theme: Themes) {
    this.themeSubject.next(theme);
  }

  changeLocalLanguageOut(language: string) {
    this.settings.localSettings.languageOut = language;
    this.localLanguageOutSubject.next(language);
  }

  changeViewportWidth(width: number) {
    this.viewportWidthSubject.next(width);
  }

  getAvailableThemes(): string[] {
    return Object.keys(Themes);
  }

  getRemoteSetSettings(
    id?: number,
    forceReload?: boolean
  ): Observable<IRemoteSettings> {
    if (forceReload || !Object.keys(this.settings.kitSettings).length) {
      // get remote settings
      return this.speakseeSettingsService.getHubSetSettings(id).pipe(
        map((settings) => {
          this.kitSubject.next({
            license: settings.license_settings,
            microphones: settings?.microphone_settings?.map((mic, index) => ({
              ...mic,
              index: index + 1,
            })),
          } as IRemoteSettings);
          return this.kitSubject.value;
        }),
        switchMap(() => this.kitSubject)
      );
    }

    return this.kit$;
  }

  getMicSettings(id: number): MicrophoneSettings {
    if (!Object.keys(this.settings.kitSettings).length) {
      throw new Error(`no settings found try reloading the settings`);
    }
    // TODO: use mic for this
    const mic = this.kitSubject.value.microphones.find((m) => m.mic_id === id);
    if (!mic) {
      throw new Error(
        `no mic in settings found with id ${id} try reloading the settings`
      );
    }
    return mic;
  }
  // TODO: Use function for color selector implementation
  updateMicSettings(
    update: Omit<UpdateMicrophoneSettingsRequest, 'event'>
  ): Observable<Record<string, unknown>> {
    const request = {
      event: 'user.settings.language.update',
      microphones: update.microphones,
    } as UpdateMicrophoneSettingsRequest;

    if (this.isQRAuthMode) {
      request.event = 'anonymous.settings.langauge_out.update';
    }

    const callback = this.speakseeSettingsService.updateMicSettings(request);

    return callback.pipe(
      map(() => {
        const currentSettings = this.kitSubject.value;
        for (const micUpdate of update.microphones) {
          const mic = currentSettings.microphones.find(
            (x) => x.mic_id === micUpdate.mic_id
          );
          mic.color_hex = micUpdate.color_hex ?? mic.color_hex;
          mic.language_in = micUpdate.language_in ?? mic.language_in;
          mic.language_out = micUpdate.language_out ?? mic.language_out;
          mic.name = micUpdate.name ?? mic.name;
        }
        this.kitSubject.next(currentSettings);
        return {};
      })
    );
  }

  public startMicSettingsPoller(stopPolling: Observable<any>) {
    if (this.isPolling) {
      console.log('polling is already started');
      return;
    }
    this.isPolling = timer(0, 10 * 1000)
      .pipe(
        takeUntil(stopPolling),
        concatMap(() => {
          const request = {
            event: 'user.settings.language.update',
          } as UpdateMicrophoneSettingsRequest;

          if (this.isQRAuthMode) {
            request.event = 'anonymous.settings.langauge_out.update';
          }
          request.microphones = this.kitSubject.value.microphones.map(
            (mic) =>
              ({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                mic_id: mic.mic_id,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                language_out: mic.language_out,
              } as MicrophoneSettings)
          );
          return this.speakseeSettingsService.updateMicSettings(request);
        })
      )
      .subscribe({
        complete: () => {
          this.isPolling = undefined;
        },
      });
  }

  private saveLocalSettings() {
    localStorage.setItem(
      'localSettings',
      JSON.stringify(this.settings.localSettings)
    );
  }
}
