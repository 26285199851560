<app-header [noButtons]="true" [enableLogout]="true"></app-header>
<div class="container">
  <mat-card class="center-card">
    <mat-card-header>
      <mat-card-title>{{
        'pages.hub-selector.header' | translate
      }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div
        *ngIf="licenses$ | async as licenses; else loading"
        class="order-container"
      >
        <ng-container *ngFor="let license of this.licenses; let i = index">
          <div
            matRipple
            *ngIf="license.type === 'microphonekit'"
            class="order-container__item"
            (click)="goToTranscription(license)"
          >
            <img src="assets/svg/kit.svg" />

            <div>Microphone Kit {{ i + 1 }}</div>
            <button
              mat-icon-button
              aria-label="Example icon button with a open in new tab icon"
            >
              <mat-icon>double_arrow</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>

  <ng-template #loading>
    <div class="loading">
      <img src="/assets/animations/loading.gif" alt="" />
    </div>
  </ng-template>
</div>
