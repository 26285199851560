<h1 style="text-align: center; padding-bottom: 20px">🐰magic zone🪄</h1>

<div class="container">
  <mat-form-field>
    <mat-label>segmentationSilenceTimeout</mat-label>
    <input
      matInput
      type="number"
      [(ngModel)]="this.localTempSettings.segmentationSilenceTimeout"
      (change)="update()"
    />
  </mat-form-field>
  <mat-checkbox
    [(ngModel)]="this.localTempSettings.splitOnFinal"
    (change)="update()"
  >
    split on final
  </mat-checkbox>
  <mat-checkbox
    [(ngModel)]="this.localTempSettings.onlyShowChangesOnFinal"
    (change)="update()"
  >
    only change on final
  </mat-checkbox>
</div>
<pre
  >{{ this.environment | json }}
</pre>
