import { inject, Injectable } from '@angular/core';
import {
  UpdateMicrophoneSettingsRequest as oldUpdateRequest,
  SpeakseeSettingsService,
} from '@speaksee/speaksee-angular-core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

export interface UpdateMicrophoneSettingsRequest extends oldUpdateRequest {
  event:
    | 'anonymous.settings.langauge_out.update'
    | 'user.settings.language.update';
}

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  private coreSettingsService = inject(SpeakseeSettingsService);
  private $http = inject(HttpClient);
  constructor() {}

  public getHubSetSettings(licenseId: number) {
    return this.coreSettingsService.getHubSetSettings(licenseId);
  }

  public updateMicSettings(
    request: Omit<UpdateMicrophoneSettingsRequest, 'event'>
  ) {
    return this.$http.put(
      `${environment.speaksee.apiUrl}/v2/settings/mics`,
      request
    );
  }
}
