import { Component, Input, OnInit } from '@angular/core';
import { Microphone } from 'src/app/shared/models/microphone.model';

@Component({
  selector: 'app-microphone',
  templateUrl: './microphone.component.html',
  styleUrls: ['./microphone.component.scss'],
})
export class MicrophoneComponent implements OnInit {
  @Input() microphone: Microphone;
  @Input() showText: boolean;

  constructor() {}

  ngOnInit() {}
}
