import { Component, OnInit } from '@angular/core';
import {
  ILocalSettings,
  SettingsService,
} from '@app/services/settings.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-developer-dialog',
  templateUrl: './developer-dialog.component.html',
  styleUrls: ['./developer-dialog.component.scss'],
})
export class DeveloperDialogComponent implements OnInit {
  public localSettings$: Observable<ILocalSettings>;
  public localTempSettings: ILocalSettings;
  public environment = {
    version: environment.version,
    versionName: environment.versionName,
    hash: environment.commitHash,
  };
  constructor(private settings: SettingsService) {
    this.localTempSettings = { ...settings.localSettings };
    this.localSettings$ = settings.localSettings$;
  }

  ngOnInit(): void {}

  update() {
    this.settings.updateLocalSettings(this.localTempSettings);
  }
}
