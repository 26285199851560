import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { License } from './license.types';
import { SpeakseeOrderService } from './license.service';

/**
 * Resolves licenses for the application.
 */
@Injectable({
  providedIn: 'root',
})
export class LicensesResolver {
  constructor(private orderService: SpeakseeOrderService) {}

  /**
   * Resolves licenses by calling the getLicenses method of the order service.
   *
   * @returns An observable of License array.
   */
  resolve(): Observable<License[]> {
    return this.orderService.getLicenses();
  }
}
